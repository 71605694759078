// @flow
import type { OutputSelector } from "reselect";
import { Map } from "immutable";
import { createSelector } from "reselect";
import type { RuleTargetingQb, SelectedTargetingRule } from "@fas/ui-core/lib/FlatQueryBuilder";
import type { List } from "immutable";
import type { MultiselectTypes, Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import { GENERAL_FILTER_FORM } from "../../helpers/constants";
import type { State } from "../../pages/AppStoreWrapper";
import { operatorsLocalizationV2, prepareOperators, typeMapper } from "../../helpers/targetingUtils";
import type { TargetingRuleOption, TargetingValueOption } from "../../reducers/dictionaries";

function getMultiselectType(type: string, values: Array<Option>) {
  if (type === "MULTISELECT" && values.length) {
    return "simpleMultiple";
  }
  return typeMapper[type];
}

export type GroupState = Map<string, *>;
export type RuleState = Map<string, *>;
export type TargetingRule = RuleState | GroupState;
export type DicQBRule = {|
  disabled: boolean,
  name: {
    value: string,
    label: string,
  },
  group?: string,
  type: string,
  components: Array<string>,
  maxTargetingRulesCount?: number,
  targetingMaxValueCount?: number,
  maxFilteringRulesCount?: number,
  filteringMaxValueCount?: number,
  operators: Array<{|
    value: string,
    name: string,
  |}>,
  allowedCampaignTypes: Array<string>,
|};
export type FormQbRule = {|
  disabled: boolean,
  name: string,
  group?: string,
  type: string,
  components: Array<string>,
  maxTargetingRulesCount?: number,
  targetingMaxValueCount?: number,
  maxFilteringRulesCount?: number,
  filteringMaxValueCount?: number,
  operators: Array<{|
    value: string,
    name: string,
  |}>,
  allowedCampaignTypes: Array<string>,
|};

export const getTargetingGroupById: OutputSelector<State, *, TargetingRule> = createSelector(
  [
    (state: State, id: string): TargetingRule => {
      const rule = state.form.getIn([GENERAL_FILTER_FORM, "conditions", "byId", id], {});
      return rule;
    },
  ],
  (rule: TargetingRule): * => rule.toJS()
);

export const getTargetingRuleById: OutputSelector<State, *, SelectedTargetingRule> = createSelector(
  [
    getTargetingGroupById,
    (state: State): List<DicQBRule> => state.dictionaries.get("targetingRules"),
  ],
  (rule: TargetingRule, dictionary: List<DicQBRule>): SelectedTargetingRule => {
    const jsDic: Array<DicQBRule> = dictionary.toJS();

    const targetingFromDic: ?DicQBRule = jsDic.find((i: DicQBRule): boolean => i.name.value === rule.name);
    const label: string = targetingFromDic ? targetingFromDic.name.label : "";
    const targetingMaxValueCount: ?number = targetingFromDic && targetingFromDic.targetingMaxValueCount
      ? targetingFromDic.targetingMaxValueCount : undefined;
    const result: SelectedTargetingRule = {
      ...rule,
      label,
      operator: {
        value: rule.operator,
        name: operatorsLocalizationV2[rule.operator],
      },
    };
    if (targetingMaxValueCount) {
      result.targetingMaxValueCount = targetingMaxValueCount;
    }

    return result;
  }
);

export function getLoadingStatusForValues(state: State, ruleName: string): boolean {
  const status: boolean = state.loading.get(`loadingTargetingValues-${ruleName}`) || false;
  const statusAll: boolean = state.loading.get("loadingTargetingValues") || false;

  return statusAll || status;
}

export const getAllTargetingRuleNames: OutputSelector<State, *, List<string>> = createSelector(
  (
    state: State,
    rootTargetingGroup: GroupState
  ): Array<TargetingRule> => rootTargetingGroup.targetings
    .map((id: string): TargetingRule => getTargetingRuleById(state, id)),
  (targetings: Array<TargetingRule>): List<string> => targetings.filter((targeting: TargetingRule): RuleState => targeting.type === "rule").map((targeting: RuleState): string => targeting.name)
);

export const getTargetingRulesList: OutputSelector<State, *, Array<RuleTargetingQb>> = createSelector(
  [
    (state: State): List<TargetingRuleOption> => state.dictionaries.get("targetingRules"),
    (state: State, groupId: string): List<string> => {
      const group: GroupState = getTargetingGroupById(state, groupId);
      return getAllTargetingRuleNames(state, group);
    },
  ],
  (dictionaries: List<TargetingRuleOption>, rulesList: List<string>): Array<RuleTargetingQb> => {
    const rules: Array<TargetingRuleOption> = dictionaries.toArray();
    const result: Array<RuleTargetingQb> = [];

    rules.forEach((ruleFromDic: TargetingRuleOption) => {
      const maxCount: number = ruleFromDic.maxTargetingRulesCount;
      if (!ruleFromDic.components.includes("targeting")) {
        return;
      }
      const setupedCounter: number = rulesList.filter((i: string): boolean => i === ruleFromDic.name.value).size;
      if (setupedCounter >= maxCount) {
        result.push({
          name: ruleFromDic.name,
          type: ruleFromDic.type,
          group: ruleFromDic.group,
          disabled: true,
          operators: prepareOperators(ruleFromDic.operators),
        });
        return;
      }
      result.push({
        name: ruleFromDic.name,
        type: ruleFromDic.type,
        group: ruleFromDic.group,
        disabled: false,
        operators: prepareOperators(ruleFromDic.operators),
      });
    });
    return result;
  }
);

export const getTargetingGroupErrorById = (state: State, id: string): string => state.errors.getIn(["targetings", id, "message"], "");

export const getOperators: OutputSelector<State, *, Array<{|
  value: string,
  name: string,
|}>> = createSelector(
  (state: State, ruleName: string): TargetingRuleOption => state.dictionaries.get("targetingRules").find((rule: TargetingRuleOption): boolean => rule.name.value === ruleName),
  (rule: TargetingRuleOption): Array<{|
    value: string,
    name: string,
  |}> => {
    if (!rule) {
      return [];
    }
    return prepareOperators(rule.operators);
  }
);

export const getValues: OutputSelector<State, *, {
  multiSelectType: MultiselectTypes,
  list: Array<Option>
}> = createSelector(
  [
    (state: State): List<TargetingValueOption> => state.dictionaries.get("targetingValues"),
    (state: State, ruleName: string): TargetingRuleOption => state.dictionaries.get("targetingRules").find((rule: TargetingRuleOption): boolean => rule.name.value === ruleName),
  ], (targetingValues: Map<string, TargetingValueOption>, targetingRule: TargetingRuleOption): {
    multiSelectType: MultiselectTypes,
    list: Array<Option>
  } => {
    if (!targetingRule) {
      return {
        list: [],
        multiSelectType: "simpleMultiple",
      };
    }
    const list: Array<Option> = targetingValues.get(targetingRule.name.value) || [];
    return {
      list,
      multiSelectType: getMultiselectType(targetingRule.type, list),
    };
  }
);

export const getTargetingErrorById: OutputSelector<State, *, {
  errorRule: { message: string } | null,
  errorValue: { message: string } | null,
  errorOperator: { message: string } | null
}> = createSelector(
  [
    (state: State, id: string): Map<string, *> => state.errors.getIn(["targetings", id], Map()),
  ],
  (error: Map<string, *>): {
    errorRule: { message: string } | null,
    errorValue: { message: string } | null,
    errorOperator: { message: string } | null
  } => error.toJS()
);

export const getAllIds: OutputSelector<State, *, string[]> = createSelector([
  (state: State): Map<string, *> => state.form.getIn([GENERAL_FILTER_FORM, "conditions", "allIds"], []),
], (allIds: string[]) => allIds);

export const getDictionary: OutputSelector<State, *, string[]> = createSelector([
  (state: State, dicName: string) => state.dictionaries.get(dicName, []),
], (dic: List<string>) => dic.toJS());
