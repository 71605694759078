// @flow
import {
  call, put, takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import {
  FETCH_GENERAL_FILTER_TARGETING_SAGA,
} from "../../helpers/constants";
import { getTargetingRules } from "../../services/generalFilterApiV2";
import { type FetchGeneralFilterTargetingSaga } from "../../actions/generalFilters";
import { setTargetingRules } from "../../actions/dictionaries";

export function* makeFetch({}: FetchGeneralFilterTargetingSaga): Saga<void> {
  try {
    yield put(setLoading(FETCH_GENERAL_FILTER_TARGETING_SAGA, true));

    const data = yield call(getTargetingRules);
    yield put(setTargetingRules(data.data.data));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(FETCH_GENERAL_FILTER_TARGETING_SAGA, false));
  }
}

export default function* watchGeneralFilterTargeingSaga(): Saga<void> {
  yield takeEvery(FETCH_GENERAL_FILTER_TARGETING_SAGA, (makeFetch: Function));
}
