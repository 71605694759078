// @flow
/* eslint-disable import/max-dependencies */
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  SMARTLINK_AGGREGATORS_TABLE,
  HISTORY_REPLACE_TABLE,
  GENERAL_FILTER_TABLE,
} from "../../helpers/constants";
import { fetchSmartlinkAggregatorsList } from "../../services/smartlinkAggregatorsApi";
import { fetchHistoryReplaceList } from "../../services/historyReplaceApi";
import watchGetFormDataSaga from "../../sagas/getFormDataSaga";
import watchSaveSmartlinkRedirect from "../../sagas/saveSmartlinkRedirectSaga";
import watchChangeSmartlinkAggregatorActive from "../../sagas/changeSmartlinkAggregatorActive";
import watchGetHistoryReplaceDropdownList from "../../sagas/getHistoryReplaceDropdownList";
import getTableSaga from "../../sagas/getTableSaga";
import watchGetSmartlinkAggregatorsToCampaigns from "../../sagas/getSmartlinkAggregatorsToCampaignSaga";

import watchSaveGeneralFilter from "../../sagas/saveGeneralFilterSaga";
import watchGetGeneralFilterSaga from "../../sagas/getGeneralFilterSaga";
import fetchTargetingValuesSaga from "../../sagas/fetchTargetingValuesSaga";
import watchGeneralFilterActive from "../../sagas/changeGeneralFilterActive";
import watchGeneralFilterClone from "../../sagas/cloneGeneralFilterSaga";
import { fetchGeneralFilterList } from "../../services/generalFilterApiV2";
import watchCommonGetDropdownSaga from "../../sagas/commonGetDropdownSaga/commonGetDropdownSaga";
import watchGeneralFilterTargeingSaga from "../../sagas/fetchGeneralFilterTargeingSaga";
import watchRemoveGeneralFilterTargetingGroup from "../../sagas/removeGeneralFilterTargetingGroup";
import watchFetchCampignsSaga from "../../sagas/getGeneralFilterCampaigns/getGeneralFilterCampaigns";

const watchGetSmartlinkAggregatorsList = getTableSaga({
  tableName: SMARTLINK_AGGREGATORS_TABLE,
  fetchMethod: fetchSmartlinkAggregatorsList,
});

const watchGetGeneralFilterList = getTableSaga({
  tableName: GENERAL_FILTER_TABLE,
  fetchMethod: fetchGeneralFilterList,
});
const watchGetHistoryReplaceList = getTableSaga({
  tableName: HISTORY_REPLACE_TABLE,
  fetchMethod: fetchHistoryReplaceList,
});

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetSmartlinkAggregatorsList),
    call(watchGetHistoryReplaceList),
    call(watchGetHistoryReplaceDropdownList),
    call(watchGetFormDataSaga),
    call(watchSaveSmartlinkRedirect),
    call(watchChangeSmartlinkAggregatorActive),
    call(watchGetSmartlinkAggregatorsToCampaigns),

    call(watchGetGeneralFilterList),
    call(watchGeneralFilterActive),
    call(watchGeneralFilterClone),

    call(watchSaveGeneralFilter),
    call(fetchTargetingValuesSaga),
    call(watchGetGeneralFilterSaga),
    call(watchCommonGetDropdownSaga),
    call(watchGeneralFilterTargeingSaga),
    call(watchRemoveGeneralFilterTargetingGroup),
    call(watchFetchCampignsSaga),
  ]);
}
