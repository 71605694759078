// @flow
import React from "react";
import { Loader, SetupCard } from "@fas/ui-core";
import {
  Box,
} from "@mui/material";
import { TextFieldForm, SelectForm, SwitchFieldForm } from "@fas/ui-core/lib/Form";
import { useDispatch, useSelector } from "react-redux";
import { getDropdown } from "@fas/ui-framework/lib/redux/selectors/dropdowns";
import type { Node } from "react";
import type { Option } from "@fas/ui-framework/lib/redux/reducers/dropdowns";
import { getFormField, setFormData } from "@fas/ui-framework";
import { removeError } from "@fas/ui-framework/lib/redux/actions/errors/actions";
import { GENERAL_FILTER_FORM, GET_GENERAL_FILTER_SAGA, SAVE_GENERAL_FILTER_SAGA } from "../../helpers/constants";
import validate from "./validation";
import Form from "../../containers/Form";
import { useLoading } from "../../hooks";
import type { State } from "../../pages/AppStoreWrapper";

function GeneralFilterInfo(): Node {
  const dispatch = useDispatch();
  const loadingPriorityList: boolean = useLoading<string>("priorityListGF-loading");
  const isGetFormloading: boolean = useLoading(GET_GENERAL_FILTER_SAGA);
  const isSaveFormloading: boolean = useLoading(SAVE_GENERAL_FILTER_SAGA);

  const priorityList: Array<Option<number>> = useSelector((state: State) => getDropdown(state, "priorityListGF"));
  const isActive: boolean = useSelector((state) => getFormField(state, GENERAL_FILTER_FORM, "isActive"));

  const handleChangeFact = (value: string | number): void => {
    dispatch(setFormData(GENERAL_FILTER_FORM, {
      fact: Number(value),
      dryModeRate: 100 - Number(value),
    }));
  };
  const handleChangeIsActive = (value: boolean): void => {
    const isActiveValue = value ? "" : null;
    dispatch(setFormData(GENERAL_FILTER_FORM, {
      isActive: Boolean(value),
      priority: isActiveValue,
    }));
  };

  const handleChangeField = (value: string | number, name: string): void => {
    dispatch(setFormData(GENERAL_FILTER_FORM, {
      [name]: value,
    }));
    dispatch(removeError([name]));
  };

  return (
    <Form id={GENERAL_FILTER_FORM} validate={validate}>
      <Loader loading={isGetFormloading || isSaveFormloading}>
        <SetupCard title="Filter info">
          <Box display="flex" width="100%">
            <Box width="100%" sx={{ paddingRight: 1 }}>
              <TextFieldForm
                name="name"
                label="Name"
                onChange={(val) => handleChangeField(val, "name")}
              />
              <Box display="flex">
                <SelectForm
                  name="priority"
                  label="Priority"
                  // $FlowFixMe
                  options={priorityList}
                  disabled={loadingPriorityList || !isActive}
                  onChange={(val) => handleChangeField(val, "priority")}
                />
                { /* $FlowFixMe no sx in type, but its support sx prop */ }
                <TextFieldForm
                  name="rate"
                  label="Rate (%)"
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                  sx={{ paddingLeft: 1 }}
                  onChange={(val) => handleChangeField(val, "rate")}
                />
              </Box>
              <Box display="flex">
                <TextFieldForm
                  name="fact"
                  label="Fact (%)"
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                  onChange={handleChangeFact}
                />
                { /* $FlowFixMe no sx in type, but its support sx prop */ }
                <TextFieldForm
                  name="dryModeRate"
                  label="Dry mode rate (%)"
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                  disabled
                  sx={{ paddingLeft: 1 }}
                  onChange={(val) => handleChangeField(val, "dryModeRate")}
                />
              </Box>
              <Box display="flex">
                <Box width="auto">
                  <SwitchFieldForm
                    name="mustHave"
                    label="Must have"
                  />
                </Box>
                <Box width="auto">
                  <SwitchFieldForm
                    name="isActive"
                    label="Is active"
                    onChange={(val) => handleChangeIsActive(val)}
                  />
                </Box>
              </Box>
            </Box>
            <Box width="100%">
              { /* $FlowFixMe no rows in type, but its support rows prop */ }
              <TextFieldForm
                name="description"
                label="Description"
                multiline
                rows={7}
                onChange={(val) => handleChangeField(val, "description")}
              />
            </Box>
          </Box>
        </SetupCard>
      </Loader>
    </Form>
  );
}

export default GeneralFilterInfo;
