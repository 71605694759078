// @flow
import Joi from "@hapi/joi";
import BaseTargeting from "./Base";

class Age extends BaseTargeting {
  getJoiSchemaValidation() {
    return super.getJoiSchemaValidation().keys({
      value: Joi.array().when("operator", {
        is: Joi.string().valid("exists", "absent"),
        then: Joi.array().min(0),
        otherwise: Joi.array()
          .min(1)
          .items(
            Joi.alternatives().try(
              Joi.string().custom((val) => {
              console.log(val);
              const age = val.split("-");

              if (age.length !== 2) {
                throw new Error("Wrong age format.");
              }
              if (Number(age[0]) > Number(age[1])) {
                throw new Error("First age value can`t be bigger than second.");
              }
              age.forEach((item) => {
                if (
                  isNaN(Number(item))
                  || !Number.isInteger(Number(item))
                  || item === ""
                  || Number(item) <= 0 || Number(item) > 100
                ) {
                  throw new Error("Wrong age format.");
                }
              });

              return val;
            }),
              Joi.object({ label: Joi.string(), value: Joi.string().custom((val) => {
                  console.log(val);
                  const age = val.split("-");

                  if (age.length !== 2) {
                    throw new Error("Wrong age format.");
                  }
                  if (Number(age[0]) > Number(age[1])) {
                    throw new Error("First age value can`t be bigger than second.");
                  }
                  age.forEach((item) => {
                    if (
                      isNaN(Number(item))
                      || !Number.isInteger(Number(item))
                      || item === ""
                      || Number(item) <= 0 || Number(item) > 100
                    ) {
                      throw new Error("Wrong age format.");
                    }
                  });

                  return val;
                }), })
            )
          ),
      }),
    });
  }
}

export default Age;
