// @flow
import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux";
import { PasteValuesPopup } from "@fas/ui-core";
import type { Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import {getTargetingGroupById, getValues} from "../../selectors/generalFilterV2";

type Props = $ReadOnly<{|
  ruleId: string,
  onClose: () => void,
  onSave: (Array<Option>) => void,
|}>;

const PasteValuesPopupContainer = ({
  ruleId,
  onClose,
  onSave,
}: Props) => {

  const  selectedRule = useSelector((state) => getTargetingGroupById(state, ruleId));
  const  ruleValuesList = useSelector((state) => getValues(state,selectedRule.name).list);

  const [selectedLabels, setSelectedLabels] = useState([]);
  const [incorrectValues, setIncorrectValues] = useState([]);

  const handleApply = (values: Array<string>) => {
    const duplicates = [];
    const incorrects = [];
    let ruleLabels = ruleValuesList.map(({ label }: Option) => label);

    if (!ruleValuesList.length) {
      ruleLabels = values.filter((value, index, array) => array.indexOf(value) === index);
    }

    values.forEach((value, index) => {
      if (!ruleLabels.includes(value)) {
        incorrects.push(value);
      }
      if (values.indexOf(value) !== index) {
        duplicates.push(value);
      }
    });

    setIncorrectValues([].concat(duplicates, incorrects));
  };

  useEffect(() => {
    const newSelectedLabels = [];
    selectedRule.value.forEach((ruleValue) => {
      const rule = ruleValuesList.find(({ value }) => (value === ruleValue.value)) || {
        label: ruleValue,
        value: ruleValue,
      };
      if (rule) {
        newSelectedLabels.push(rule.label);
      }
    });

    setSelectedLabels(newSelectedLabels);
  }, [selectedRule.value]);

  return (
    <PasteValuesPopup
      open={true}
      ruleName={selectedRule.name}
      values={selectedLabels}
      incorrectValues={incorrectValues}
      handleClose={() => {
        setIncorrectValues([]);
        onClose();
      }}
      handleApply={handleApply}
      handleSave={(newValues) => {
        const values = [];

        newValues.forEach((ruleValue) => {
          const rule = ruleValuesList.find(({ label }) => (label === ruleValue)) || {
            label: ruleValue,
            value: ruleValue,
          };
          if (rule) {
            values.push(rule);
          }
        });

        onSave(values);
      }}
    />
  );
};

export default PasteValuesPopupContainer;
