// @flow
import React, { useEffect, type StatelessFunctionalComponent } from "react";
import { SlideModal } from "@fas/ui-core";
import { useSelector, useDispatch } from "react-redux";
import type { OutputSelector } from "reselect";
import {
  Box,
  Divider,
  Button,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeModalsSelector, type StoreWithModals } from "@fas/ui-framework/lib/redux/selectors/modals";
import { getTableFilters, getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { changeTableFilters, setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import type { Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { toggleModal } from "@fas/ui-framework/lib/redux/actions/modals";
import { getFormField, setFormData } from "@fas/ui-framework";
import { removeError } from "@fas/ui-framework/lib/redux/actions/errors/actions";
import {
  GENERAL_FILTER_CAMPAIGNS_IN_MODAL,
  GENERAL_FILTER_CAMPAIGNS_OUT_MODAL, GENERAL_FILTER_FORM,
  GENERAL_FILTERS_CAMPAIGNS_TABLE,
} from "../../helpers/constants";
import { useClearTable, type ClearTableHook } from "../../hooks";
import GeneralFilterCampaignsList from "../../containers/GeneralFilterCampaignsList";
import GeneralFilterCampaignsFilter from "../../containers/GeneralFilterCampaignsFilters";
import type { CampaignType } from "../../reducers/generalFilterInfo";
import type { GeneralFilterCampaign } from "../../containers/GeneralFilterCampaignsList/types/GeneralFilterCampaignsList.types";

type ShortCampaign = { id: string, weight: number };

type GeneralFilterCampaignsModalHook = {
  open: boolean,
  limit: number,
  onSave: () => mixed,
  onClose: () => mixed,
};

const MAX_CAMPAIGNS_OUT: number = 5;
const MAX_CAMPAIGNS_IN: number = 100;

type ModalsType = typeof GENERAL_FILTER_CAMPAIGNS_IN_MODAL | typeof GENERAL_FILTER_CAMPAIGNS_OUT_MODAL;

const selectModal: OutputSelector<StoreWithModals<ModalsType>, ModalsType, boolean> = makeModalsSelector();

const useGeneralFilterCampaignsModal: () => GeneralFilterCampaignsModalHook = () => {
  const dispatch: <A>(A) => A = useDispatch();

  const campaignsInModalOpen: boolean = useSelector((state) => selectModal(state, GENERAL_FILTER_CAMPAIGNS_IN_MODAL));
  const campaignsOutModalOpen: boolean = useSelector((state) => selectModal(state, GENERAL_FILTER_CAMPAIGNS_OUT_MODAL));

  const campaignsIn: CampaignType[] = useSelector((state) => getFormField(state, GENERAL_FILTER_FORM, "tdsCampaignsIn"));
  const campaignsOut: CampaignType[] = useSelector((state) => getFormField(state, GENERAL_FILTER_FORM, "tdsCampaignsOut"));

  const limit: number = campaignsInModalOpen ? MAX_CAMPAIGNS_IN : MAX_CAMPAIGNS_OUT;

  const selected: CampaignType[] = useSelector((state) => getTableSelections(state, GENERAL_FILTERS_CAMPAIGNS_TABLE));

  const { clearTableData, clearTableFilters }: ClearTableHook = useClearTable(GENERAL_FILTERS_CAMPAIGNS_TABLE);

  const filters: Filters = useSelector((state) => getTableFilters(state, GENERAL_FILTERS_CAMPAIGNS_TABLE));
  const open: boolean = campaignsInModalOpen || campaignsOutModalOpen;

  useEffect(() => {
    if (open) {
      const type: string = campaignsInModalOpen ? "smartlink" : "basic";

      dispatch(changeTableFilters(GENERAL_FILTERS_CAMPAIGNS_TABLE, { ...filters, type }));
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      return;
    }

    if (campaignsInModalOpen) {
      dispatch(setTableSelection(GENERAL_FILTERS_CAMPAIGNS_TABLE, campaignsIn.map((c) => ({ id: c }))));
    }
    if (campaignsOutModalOpen) {
      dispatch(setTableSelection(
        GENERAL_FILTERS_CAMPAIGNS_TABLE,
        campaignsOut.map(({ id, weight }) => ({ id, weight }))
      ));
    }
  }, [open]);

  const onClose: () => mixed = () => {
    clearTableData();
    clearTableFilters();

    const modalKey: ModalsType = campaignsInModalOpen
      ? GENERAL_FILTER_CAMPAIGNS_IN_MODAL
      : GENERAL_FILTER_CAMPAIGNS_OUT_MODAL;
    dispatch(toggleModal(modalKey, false));
  };

  const onSave: () => mixed = () => {
    if (campaignsInModalOpen) {
      const ids: string[] = selected.map(({ id }: CampaignType): string => id);
      dispatch(setFormData(GENERAL_FILTER_FORM, { tdsCampaignsIn: [...new Set([...campaignsIn, ...ids])] }));
      dispatch(removeError(["tdsCampaignsIn"]));
    }

    if (campaignsOutModalOpen) {
      const ids: ShortCampaign[] = selected.map(({ id, weight = 0 }: CampaignType): ShortCampaign => ({ id, weight }));
      dispatch(setFormData(GENERAL_FILTER_FORM, { tdsCampaignsOut: ids }));
      dispatch(removeError(["tdsCampaignsOut"]));
    }

    onClose();
  };

  return {
    open,
    limit,
    onClose,
    onSave,
  };
};

const GeneralFilterModal: StatelessFunctionalComponent<{}> = () => {
  const {
    open,
    limit,
    onClose,
    onSave,
  }: GeneralFilterCampaignsModalHook = useGeneralFilterCampaignsModal();

  const rowSelectAvailable: (GeneralFilterCampaign, GeneralFilterCampaign[]) => boolean = (
    _,
    selected
  ) => selected.length < limit;

  return (
    <SlideModal
      open={open}
      header={(
        <>
          <Box display="flex">
            <Box m={3}>
              <Button onClick={onClose} data-testid="general-back-button">
                <CloseIcon />
                Back
              </Button>
            </Box>
            <Divider orientation="vertical" />
            <Box m={3}>
              <Typography variant="h5">SETUP CAMPAIGN</Typography>
            </Box>
          </Box>
          <Divider />
        </>
      )}
      content={(
        <>
          <GeneralFilterCampaignsFilter />
          <GeneralFilterCampaignsList rowSelectAvailable={rowSelectAvailable} />
        </>
      )}
      onSave={onSave}
      onClose={onClose}
    />
  );
};

export default GeneralFilterModal;
