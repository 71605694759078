// @flow
import Joi from "@hapi/joi";
import BaseTargeting from "./Base";

class EntriesCount extends BaseTargeting {
  getJoiSchemaValidation() {
    return super.getJoiSchemaValidation().keys({
      value: Joi.array().min(1).items(
        Joi.alternatives().try(
          Joi.number().min(0).max(9007199254740991),
          Joi.object({ label: Joi.string(), value: Joi.number().min(0).max(9007199254740991) })
        )
      ),
    });
  }
}

export default EntriesCount;
