// @flow
import React, { useState } from "react";
import { SetupCard, Loader } from "@fas/ui-core";
import {
  Box, Chip, TextField, Button, Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getFormField, setFormData, getErrorMessageSelector } from "@fas/ui-framework";
import { CloseSharp } from "@mui/icons-material";
import { removeError } from "@fas/ui-framework/lib/redux/actions/errors/actions";
import { toggleModal } from "@fas/ui-framework/lib/redux/actions/modals";
import {
  GENERAL_FILTER_CAMPAIGNS_IN_MODAL,
  GENERAL_FILTER_FORM,
  GET_GENERAL_FILTER_SAGA,
  SAVE_GENERAL_FILTER_SAGA,
} from "../../helpers/constants";
import { useLoading } from "../../hooks";

function GeneralFilterCampaignIn() {
  const dispatch = useDispatch();
  const [enteredCampaigns, setCampaignsIn] = useState("");

  const campaignsIn: string[] = useSelector((state) => getFormField(state, GENERAL_FILTER_FORM, "tdsCampaignsIn"));
  const isGetFormloading: boolean = useLoading(GET_GENERAL_FILTER_SAGA);
  const isSaveFormloading: boolean = useLoading(SAVE_GENERAL_FILTER_SAGA);
  const errorMessage: string = useSelector((state) => getErrorMessageSelector(state, "tdsCampaignsIn"));

  const handleRemoveCampaignIn = (idx: number) => {
    // $FlowFixMe
    dispatch(setFormData(GENERAL_FILTER_FORM, { tdsCampaignsIn: campaignsIn.toSpliced(idx, 1) }));
    dispatch(removeError(["tdsCampaignsIn"]));
  };

  const handleManualEnter = (e) => {
    if (e.key === "Enter") {
      const { value } = e.target;
      const newValues: string[] = value.split(",");
      dispatch(setFormData(GENERAL_FILTER_FORM, { tdsCampaignsIn: [...new Set([...campaignsIn, ...newValues])] }));
      dispatch(removeError(["tdsCampaignsIn"]));
      setCampaignsIn("");
    }
  };

  const handleChoiceCampaign = () => {
    dispatch(toggleModal(
      GENERAL_FILTER_CAMPAIGNS_IN_MODAL,
      true
    ));
  };

  return (
    <Loader loading={isGetFormloading || isSaveFormloading}>
      <SetupCard title="TDS Campaign in">
        <>
          <Box mb={2} width={1} display="flex" alignItems="center" justifyContent="center">
            <TextField
              label="Add campaigns manualy here"
              size="small"
              fullWidth
              variant="outlined"
              type="text"
              margin="dense"
              value={enteredCampaigns}
              onChange={(e) => setCampaignsIn(e.target.value)}
              onKeyPress={handleManualEnter}
            />
            <Typography px={1}>
              or
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ whiteSpace: "nowrap" }}
              onClick={handleChoiceCampaign}
            >
              Choice campaign
            </Button>
          </Box>
          <Box width={1} display="flex" justifyContent="flex-start" mr={1} flexWrap="wrap" sx={{ borderBottom: (errorMessage ? "1px solid red" : "") }}>
            {!isGetFormloading && campaignsIn.map((c, idx) => (
              <Chip
                key={c}
                size="small"
                label={c}
                onDelete={() => handleRemoveCampaignIn(idx)}
                deleteIcon={<CloseSharp />}
                sx={{ marginRight: 1, marginBottom: 1 }}
              />
            ))}
          </Box>
          {errorMessage && (<Box sx={{ color: "red" }}>{errorMessage}</Box>)}
        </>
      </SetupCard>
    </Loader>
  );
}

export default GeneralFilterCampaignIn;
