// @flow
export default (baseUrl: string) => ({
  baseUrl,
  logoutUrl: `${baseUrl}/logout`,
  appLinks: {
    dmp: "https://rel-admin-dmp.insigit.com/",
    jus: "https://jus.rel.insigit.com/",
    cpa: "https://cpa-admin.rel.insigit.com/",
    webPush: "https://webpush.rel.noclef.com/",
    adsBox: "https://adsbox.rel.insigit.com/",
    reports: "https://reports.rel.insigit.com/managersReport",
    crm: "https://crm.rel.insigit.com/",
  },
  links: {
    viewCampaign: `${baseUrl}/unitedReborn/campaign/view`,
    templateList: "/funnelTemplates/list",
    templateView: "/funnelTemplates/view",
    viewOldGeneralFilter: `${baseUrl}/dictionary/generalFilter/view?id=`, // FIX MTU-92090
    editGeneralFilter: "/generalFilter/edit",
  },
  endpoints: {
    get: {
      apiKey: `${baseUrl}/api/login`,
      campaignJsonById: `${baseUrl}/api/v1/campaigns/{campaignId}`,
      loginUrl: `${baseUrl}/login`,
      campaign: `${baseUrl}/unitedReborn/v2/smartlink/edit`,
      campaignId: `${baseUrl}/unitedReborn/{campaignType}/id`,
      basicCampaignsUrlById: `${baseUrl}/tds/campaign/listForBullets`,
      domainsList: `${baseUrl}/unitedReborn/campaign/domain`,
      targetingRules: `${baseUrl}/tds/campaign/v2/targetings/list`,
      targetingRulesGeneralFilter: `${baseUrl}/api/v1/targetings/list`,
      targetingValues: `${baseUrl}/tds/campaign/v2/targetings/values`,
      templateView: `${baseUrl}/dictionary/funnelTemplates/view`,
      templateInfo: `${baseUrl}/dictionary/funnelTemplates/info`,
      template: `${baseUrl}/dictionary/funnelTemplates/create`,
      funnelTemplateList: `${baseUrl}/dictionary/funnelTemplates/list`,
      funnelTemplateReportList: `${baseUrl}/dictionary/funnelTemplates/report`,
      exportFunnelTemplateReport: `${baseUrl}/dictionary/funnelTemplates/report/export`,
      antifraudDictionariesList: `${baseUrl}/api/v1/frodi/dictionaries/list`,
      antifraudDictionary: `${baseUrl}/api/v1/frodi/dictionaries`,
      antifraudDictionaryActionsList: `${baseUrl}/api/v1/frodi/dictionaries/actions/list`,
      antifraudTriggerList: `${baseUrl}/api/v1/frodi/triggers/list`,
      antifraudTrigger: `${baseUrl}/api/v1/frodi/triggers`,
      antifraudConditionsList: `${baseUrl}/api/v1/frodi/triggers/variables/list`,
      antifraudConditionsValues: `${baseUrl}/api/v1/frodi/triggers/dictionaries/list`,
      antifraudScenariosList: `${baseUrl}/api/v1/frodi/scenarios/list`,
      antifraudScenario: `${baseUrl}/api/v1/frodi/scenarios`,
      antifraudVariablesList: `${baseUrl}/api/v1/frodi/variables/list`,
      antifraudVariable: `${baseUrl}/api/v1/frodi/variables`,
      antifraudVariablesDropdown: `${baseUrl}/api/v1/frodi/variables/{name}/list`,
      getFrodiList: `${baseUrl}/api/v1/frodi/{dictionary}/{type}/list`,
      getShortUrl: `${baseUrl}/unitedReborn/shortUrl`,
      filterSmartlinkAggregators: `${baseUrl}/api/v1/smartlinkAggregators/filter`,
      getSmartlinkAggregatorsBySmartlinkId: `${baseUrl}/api/v1/smartlinkAggregators/{id}`,
      getSmartlinkAggregatorsDropdown: `${baseUrl}/api/v1/smartlinkAggregators/dropdown`,
      generalFilters: `${baseUrl}/unitedReborn/campaign/v2/generalFilter`,
      generalFilter: `${baseUrl}/api/v1/generalFilterV2/{id}`,
      generalFilterPiorityList: `${baseUrl}/api/v1/generalFilterV2/priorityList`,
      generalFilterClone: `${baseUrl}/api/v1/generalFilterV2/clone/{id}`,
      generalFilterAdd: `${baseUrl}/api/v1/generalFilter/add`, // FIX MTU-92090
      generalFilterEdit: `${baseUrl}/api/v1/generalFilter/edit/{id}`, // FIX MTU-92090
      generalFilterRedirectToIndex: `${baseUrl}/dictionary/generalFilter`, // FIX MTU-92090
      getHistoryReplaceForm: `${baseUrl}/api/v1/historyReplaceLP/{id}`,
      getHistoryReplaceDropdown: `${baseUrl}/api/v1/historyReplaceLP/dropdown`,
      getSmartlinkAggregatorsToCampaignDropdown: `${baseUrl}/api/v1/dictionary/smartlinkAggregatorsToCampaign/dropdown`,
    },
    post: {
      offersList: `${baseUrl}/tds/campaign/v2/offers`,
      saveCampaign: `${baseUrl}/unitedReborn/v2/smartlink/create`,
      saveTemplate: `${baseUrl}/dictionary/funnelTemplates/create`,
      templateCampaigns: `${baseUrl}/dictionary/funnelTemplates/campaigns`,
      antifraudTriggerActive: `${baseUrl}/api/v1/frodi/triggers/changeIsActive`,
      antifraudTrigger: `${baseUrl}/api/v1/frodi/triggers`,
      antifraudDictionary: `${baseUrl}/api/v1/frodi/dictionaries`,
      antifraudScenario: `${baseUrl}/api/v1/frodi/scenarios`,
      antifraudScenarioActive: `${baseUrl}/api/v1/frodi/scenarios/changeIsActive`,
      antifraudVariable: `${baseUrl}/api/v1/frodi/variables`,
      antifraudVariableActive: `${baseUrl}/api/v1/frodi/variables/statusChange`,
      getFrequencyCapForJumpsList: `${baseUrl}/api/v1/jumpsForFrequencyCap`,
      smartlinkAggregators: `${baseUrl}/api/v1/smartlinkAggregators`,
      getSmartlinkAggregators: `${baseUrl}/api/v1/smartlinkAggregators/list`,
      generalFilterGetCampaigns: `${baseUrl}/tds/campaign/short`,
      generalFilterSave: `${baseUrl}/api/v1/generalFilter/edit`, // FIX MTU-92090
      generalFilterUpdate: `${baseUrl}/api/v1/generalFilter/edit/{id}`, // FIX MTU-92090
      generalFilterList: `${baseUrl}/api/v1/generalFilterV2/list`,
      generalFilter: `${baseUrl}/api/v1/generalFilterV2`,
      getSmartlinkAggregatorsToCampaigns: `${baseUrl}/api/v1/template/smartlinkAggregatorsToCampaign/filter`,
      getHistoryReplaceList: `${baseUrl}/api/v1/historyReplaceLP/list`,
    },
    put: {
      antifraudTrigger: `${baseUrl}/api/v1/frodi/triggers`,
      antifraudDictionary: `${baseUrl}/api/v1/frodi/dictionaries`,
      antifraudScenario: `${baseUrl}/api/v1/frodi/scenarios`,
      antifraudVariable: `${baseUrl}/api/v1/frodi/variables`,
      changeIsFrequencyCap: `${baseUrl}/api/v1/jumpsForFrequencyCap`,
      updateSmartlinkAggregators: `${baseUrl}/api/v1/smartlinkAggregators/{id}`,
      smartlinkAggregatorActive: `${baseUrl}/api/v1/smartlinkAggregators/switchIsActive`,
      generalFilter: `${baseUrl}/api/v1/generalFilterV2/{id}`,
      generalFilterActive: `${baseUrl}/api/v1/generalFilterV2/switchIsActive`,
    },
    delete: {
      funnelsTemplate: "",
    },
    redirect: {
      manageCampaigns: `${baseUrl}/tds/manageCampaigns`,
      generalFilter: `/generalFilter/list`,
      antifraudTriggersList: "/antifraudTriggers/list",
      antifraudDictionariesList: "/antifraudDictionaries/list",
      antifraudScenariosList: "/antifraudScenarios/list",
      antifraudVariablesList: "/antifraudVariables/list",
      frequencyCupForJumps: "/frequencyCapForJumps",
      smartlinkAggregatorsList: "/smartlinkAggregators/list",
      historyReplaceList: "/historyReplace/list",
    },
  },
});
