// @flow
import React from "react";
import type { Node } from "react";
import type { DropResult } from "@hello-pangea/dnd";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { Box } from "@mui/material";

export type ListItem = { key: string };

function DragList<T: ListItem>({
  onDragEnd,
  items,
  renderItem,
}: {
  items: T[],
  renderItem: T => Node,
  onDragEnd?: (drag: DropResult) => void,
}) {
  const dropContextProps = {};
  if (onDragEnd) {
    dropContextProps.onDragEnd = onDragEnd;
  }
  return (
    <DragDropContext {...dropContextProps}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <Box ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <Draggable
                key={item.key}
                draggableId={item.key}
                index={index}
                isDragDisabled={!onDragEnd}
              >
                {(draggableProvided) => (
                  <Box
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                  >
                    {renderItem(item)}
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DragList;
