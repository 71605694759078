// @flow
import Joi from "@hapi/joi";
import BaseTargeting from "./Base";

class RegDate extends BaseTargeting {
  getJoiSchemaValidation() {
    return super.getJoiSchemaValidation().keys({
      value: Joi.array()
        .min(1)
        .max(1)
        .items(
          Joi.alternatives().try(
            Joi.string().pattern(
              /^([0-9]|[1-8][0-9]|9[0-9]|[12][0-9]{2}|300)$/,
              "must be a number from 0 to 300"
            ),
            Joi.object({ label: Joi.string(), value: Joi.string().pattern(
                /^([0-9]|[1-8][0-9]|9[0-9]|[12][0-9]{2}|300)$/,
                "must be a number from 0 to 300"
              )})
          )
        ),
    });
  }
}

export default RegDate;
