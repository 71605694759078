// @flow
/* eslint-disable import/max-dependencies */
import React, {
  memo,
  type StatelessFunctionalComponent,
  type AbstractComponent,
  type Node,
  useEffect,
  useMemo,
} from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  type DroppableProvided,
  type DraggableProvided,
  type DraggableStateSnapshot,
  type DraggableRubric,
} from "@hello-pangea/dnd";
import { FixedSizeList, areEqual } from "react-window";
import AutoSizer, { type AutoSizerProps } from "react-virtualized-auto-sizer";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import FunnelListHeader from "../FunnelListHeader";
import { dragEndWrapper } from "../../helpers/dndUtil";
import type { Props, RowProps } from "./types/FunnelList.types";

const DragIndicator = (props) => (
  <div {...props}>
    <DragIndicatorIcon color="action" />
  </div>
);

const Row: AbstractComponent<RowProps> = memo<RowProps>(({
  data = {},
  index,
  style,
}: RowProps): Node => {
  const {
    funnelsIds,
    splitId,
    FunnelItemComponent,
  } = data;

  const id: string = funnelsIds[index];

  return (
    <Draggable key={`funnel-drag-item-${id}`} draggableId={`funnel-drag-item-${id}`} index={index}>
      {(draggableProvided: DraggableProvided): Node => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const DragHandleComponent = useMemo(() => () => (
          <DragIndicator
            {...draggableProvided.dragHandleProps}
            style={{ cursor: "move" }}
          />
        ), [draggableProvided.dragHandleProps]);

        return (
          <div
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            style={{
              ...draggableProvided.draggableProps.style,
              ...style,
              margin: 0,
            }}
            data-index={index}
          >
            <FunnelItemComponent
              funnelId={id}
              splitId={splitId}
              DragHandleComponent={DragHandleComponent}
            />
          </div>
        );
      }}
    </Draggable>
  );
}, areEqual);

const useStyles = makeStyles((theme) => (
  {
    container: {
      height: `calc(100% - ${theme.spacing(2)})`,
      padding: `${theme.spacing(1)} 0px`,
      borderRadius: 5,
      backgroundColor: "#fff",
    },
  }
));

const FunnelList: StatelessFunctionalComponent<Props> = ({
  funnelsIds,
  splitId,
  handleOpenFunnel,
  onMoveRules,
  scrollToItem,
  FunnelItemComponent = ({ funnelId }) => <div>{funnelId}</div>,
  SearchComponent,
}: Props) => {
  const classes = useStyles();
  const listRef = React.createRef();

  useEffect(() => {
    if (listRef.current && scrollToItem) {
      listRef.current.scrollToItem(funnelsIds.indexOf(scrollToItem));
    }
  }, [funnelsIds, listRef.current, scrollToItem]);

  return (
    <Box className={classes.container}>
      <FunnelListHeader
        Search={SearchComponent}
        onAdd={(): void => handleOpenFunnel(splitId, null)}
        columns={
          [
            { width: 1, dataTestid: "empty-header-cell-0", noWrap: false },
            {
              width: 4,
              title: "Name",
              dataTestid: "name",
              noWrap: true,
            },
            {
              width: 1,
              title: "Targetings",
              dataTestid: "targetings",
              noWrap: true,
            },
            {
              width: 1,
              title: "Offers",
              dataTestid: "offers",
              noWrap: true,
            },
            {
              width: 1,
              title: "Filterings",
              dataTestid: "filterings",
              noWrap: true,
            },
            { width: 4, dataTestid: "empty-header-cell-1", noWrap: false },
          ]
        }
      />
      <Box px={2} height="calc(100% - 90px)">
        <DragDropContext onDragEnd={
          dragEndWrapper(
            funnelsIds,
            (ids: Array<string>): void => onMoveRules(splitId, ids)
          )
        }
        >
          <Droppable
            droppableId={`${splitId}-funnel-droppable`}
            mode="virtual"
            renderClone={(
              provided: DraggableProvided,
              snapshot: DraggableStateSnapshot,
              rubric: DraggableRubric
            ): Node => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{
                  ...provided.draggableProps.style,
                  margin: 0,
                }}
                data-index={rubric.source.index}
              >
                <FunnelItemComponent
                  funnelId={funnelsIds[rubric.source.index]}
                  splitId={splitId}
                  DragHandleComponent={DragIndicator}
                />
              </div>
            )}
          >
            {(droppableProvided: DroppableProvided): Node => (
              <AutoSizer
                defaultWidth={1}
                defaultHeight={1}
              >
                {({ height, width }: AutoSizerProps): Node => (
                  <FixedSizeList
                    ref={listRef}
                    height={height}
                    itemCount={funnelsIds.length}
                    itemSize={70}
                    outerRef={droppableProvided.innerRef}
                    width={width}
                    itemData={{
                      funnelsIds,
                      splitId,
                      FunnelItemComponent,
                    }}
                  >
                    {Row}
                  </FixedSizeList>
                )}
              </AutoSizer>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  );
};

export default FunnelList;
