// @flow
import React, { useEffect } from "react";
import {
  Box, Typography, IconButton, Link,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Loader } from "@fas/ui-core";
import type { FunnelTemplate as Template } from "../../actions/dictionaries";

type Props = {
  templateId?: string,
  template?: Template,
  onFunnelTemplateFetch: (templateId: string) => void,
  onOpen: () => void,
  onDelete: Function,
  isActive: boolean,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.blueBackgroundLight,
    border: `1px solid ${theme.palette.borderColor}`,
    borderRadius: theme.borderRadius,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const defaultTemplateName = "None";

const FunnelTemplate = ({
  templateId = "",
  template,
  onFunnelTemplateFetch,
  onOpen,
  onDelete,
  isActive,
}: Props) => {
  const styles = useStyles();

  useEffect(() => {
    if (templateId && !template) {
      onFunnelTemplateFetch(templateId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);

  return (
    <>
      <Box
        align="left"
        pb={1}
        pt={2}
      >
        <Typography
          variant="subtitle1"
          component="p"
        >
          Technical Template
        </Typography>
      </Box>
      <Box
        pb={1}
      >
        <Box px={2} className={styles.root}>
          <Loader loading={!template && !!templateId}>
            <Typography>
              {(template && template.name) || templateId || defaultTemplateName}
            </Typography>
          </Loader>

          <Box>
            {templateId && (
              <>
                <IconButton
                  disabled={!isActive}
                  onClick={(e) => {
                    onDelete(e);
                  }}
                  data-testid="delete-template-button"
                >
                  <DeleteIcon />
                </IconButton>
                <Link
                  target="_blank"
                  href={`/funnelTemplates/view/${templateId}`}
                  underline="hover"
                >
                  <IconButton disabled={!isActive}>
                    <VisibilityIcon />
                  </IconButton>
                </Link>
              </>
            )}
            <IconButton
              onClick={onOpen}
              disabled={!isActive}
              data-testid="add-template-button"
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FunnelTemplate;
