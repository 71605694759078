// @flow
import { GET_COMMON_DICTIONARY_SAGA, GET_DICTIONARY_SAGA } from "../../helpers/constants";
import type { DictionaryParams } from "../../hooks/useDictionary";

export type GetDictionarySaga = {|
  type: typeof GET_DICTIONARY_SAGA,
  params: DictionaryParams,
|}

export type GetCommonDropdownSaga = {
  type: typeof GET_COMMON_DICTIONARY_SAGA,
  endpointKey: string,
  name: string,
}

export function getDictionarySaga(params: DictionaryParams): GetDictionarySaga {
  return {
    type: GET_DICTIONARY_SAGA,
    params,
  };
}

export function getCommonDropdownSaga(endpointKey: string, name: string): GetCommonDropdownSaga {
  return {
    type: GET_COMMON_DICTIONARY_SAGA,
    endpointKey,
    name,
  };
}
