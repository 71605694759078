// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { Map } from "immutable";
import type { Sorting } from "../../sagas/getTableSaga/getTableSaga";
import { requestService } from "../request";
import type { GeneralFilterListItem } from "../../containers/GeneralFilterList/type";

export type SchemaType = {
  [string]: {
    type: string,
    targetings?: Array<string>,
    operators: Array<string>,
    value?: Array<*>,
  },
}

export type GeneralFilter = {
  id?: number,
  name: string,
  description: string,
  priority: number,
  rate: number,
  mustHave: boolean,
  isActive: boolean,
  dryModeRate: number,
  conditions: Map<SchemaType>,
  tdsCampaignsIn: Array<string>,
  tdsCampaignsOut: Array<string>,
};

export const fetchGeneralFilterList: (
  options: {
    page: number,
    pageSize: number,
    filters: { [string]: mixed },
    sorting?: Sorting,
  },
) => PromiseResponse<{ data: { data: Array<GeneralFilterListItem>, count: number } }> = (
  options
) => {
  const { filters, sorting, ...params }: {
    page: number,
    pageSize: number,
    filters: { [string]: mixed },
    sorting?: Sorting,
  } = options;
  let newSorting: Sorting;
  if (sorting && Object.keys(sorting).length) {
    const current = Object.keys(sorting)[0];
    newSorting = {
      current,
      direction: sorting[current],
    };
  }
  // $FlowFixMe
  const paramsData = {
    ...params,
    // $FlowFixMe
    filters: {
      ...filters,
      ...(filters.isActive && { isActive: Number(filters.isActive) }),
    },
    ...(newSorting && { sorting: newSorting }),
  };

  return requestService
    .post(environment.endpoints.post.generalFilterList, paramsData)
    .then(({ data: { data, ...rest } }): Array<GeneralFilterListItem> => ({
      ...rest,
      data: data.map(({ isActive, mustHave, ...generalFIlterData }: GeneralFilterListItem): GeneralFilterListItem => ({
        ...generalFIlterData,
        isActive: Boolean(isActive),
        mustHave: Boolean(mustHave),
      })),
    }));
};
export const changeGeneralFilterActive: (
  ids: number[],
  isActive: boolean
) => PromiseResponse<{}> = (ids, isActive) => requestService
  .put(environment.endpoints.put.generalFilterActive, { ids, isActive: (isActive) ? 1 : 0 });

export const cloneGeneralFilter: (
  id: number,
) => PromiseResponse<{}> = (id) => requestService
  .get(environment.endpoints.get.generalFilterClone.replace("{id}", `${id}`))
  .then(({ data }): GeneralFilterListItem => data.data);

export const getGeneralFilter: (
  id: number
) => PromiseResponse<{ data: GeneralFilterListItem }> = (id) => requestService
  .get(environment.endpoints.get.generalFilter.replace("{id}", `${id}`))
  .then(({ data }): GeneralFilterListItem => ({
    ...data.data,
    isActive: Boolean(data.data.isActive),
    mustHave: Boolean(data.data.mustHave),
  }));

export const getGeneralFilterPriorityList: () => PromiseResponse<mixed> = () => requestService
  .get(environment.endpoints.get.generalFilterPiorityList)
  .then(({ data }): GeneralFilterListItem => data.data);

export const getCampaigns: (number, number, Object) => PromiseResponse<mixed> = (
  page,
  pageSize,
  filters
) => requestService.post(environment.endpoints.post.generalFilterGetCampaigns, {
  ...filters,
  page,
});

export const getTargetingRules: () => PromiseResponse<mixed> = () => requestService
  .get(environment.endpoints.get.targetingRulesGeneralFilter);

export const getValuesForTargeting: (string) => PromiseResponse<mixed> = (ruleName) => requestService
  .get(environment.endpoints.get.targetingValues, {
    params: {
      rule: ruleName,
    },
  });

export const createGeneralFilter: (
  data: GeneralFilter,
) => PromiseResponse<*> = (body) => requestService
  .post(environment.endpoints.post.generalFilter, body);

export const updateGeneralFilter: (
  data: GeneralFilter,
  id: number,
) => PromiseResponse<*> = (body, id) => requestService
  .put(environment.endpoints.put.generalFilter.replace("{id}", `${id}`), body);
