// @flow
import React, { useState, useCallback, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Create from "@mui/icons-material/Create";
import Close from "@mui/icons-material/Close";
import Check from "@mui/icons-material/Check";

type Props = {
  text: string,
  textLimit?: number,
  placeholder: string,
  onTextChange: Function,
}

const useStyles = makeStyles(() => ({
  text: {
    fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
    cursor: "pointer",
  },
}));

const TextEdit = ({
  text, textLimit = 100, placeholder, onTextChange,
}: Props) => {
  const classes = useStyles();
  const [isEditable, setMode] = useState(false);
  const [_text, setText] = useState("");

  useEffect(() => {
    setText(text);
  }, [text]);

  const handleTextChange = useCallback((e) => {
    const { value } = e.target;
    if (value.length <= textLimit) {
      setText(value);
    }
  }, [textLimit]);

  function handleClose() {
    setMode(false);
    setText(text);
  }

  function handleSave() {
    setMode(false);
    onTextChange(_text);
  }

  return (
    <Box>
      {
        isEditable
        && (
          <Box justifyContent="center">
            <TextField
              data-testid="input-text"
              placeholder={placeholder}
              value={_text}
              onChange={handleTextChange}
            />
            <IconButton
              data-testid="text-edit-check"
              aria-label="check"
              onClick={() => handleSave()}
            >
              <Check fontSize="small" />
            </IconButton>
            <IconButton
              data-testid="text-edit-close"
              aria-label="close"
              onClick={() => handleClose()}
            >
              <Close fontSize="small" />
            </IconButton>
          </Box>
        )
      }
      {
        !isEditable
        && (
          <div>
            <span
              className={classes.text}
              data-testid="diplayed-text"
              role="button"
              onClick={() => setMode(true)}
            >
              {text}
            </span>
            <IconButton
              size="small"
              data-testid="text-edit-create"
              aria-label="create"
              onClick={() => setMode(true)}
            >
              <Create fontSize="small" />
            </IconButton>
          </div>
        )
      }
    </Box>
  );
};

export default TextEdit;
