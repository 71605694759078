/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put, select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import type { RuleTargetingQb } from "@fas/ui-core/lib/FlatQueryBuilder";
import { prepareFetchErrors } from "@fas/ui-framework/lib/services/generators";
import { createGeneralFilter, updateGeneralFilter } from "../../services/generalFilterApiV2";
import { GENERAL_FILTER_FORM, SAVE_GENERAL_FILTER_SAGA } from "../../helpers/constants";
import ExternalRedirect from "../../components/App/ExternalRedirect";
import validator from "../../services/campaignValidation";
import type { Errors } from "../../services/campaignValidation/types/Base";
import { getDictionary } from "../../selectors/generalFilterV2";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(SAVE_GENERAL_FILTER_SAGA, true));

    const schema: Array<RuleTargetingQb> = yield select((state) => state.form.getIn([GENERAL_FILTER_FORM, "conditions", "byId"]));
    const targetingRulesList = yield select((state) => getDictionary(state, "targetingRules"));
    const Validator = validator("smartlink");
    // $FlowFixMe
    const targettingErrors: Errors = new Validator().validateTargetingV2(schema.toJS(), targetingRulesList);
    // $FlowFixMe
    yield put(setErrors(targettingErrors));

    if (Object.keys(targettingErrors).length) {
      yield put(addNotification({ message: "Error at targeting rules", severity: "error" }));
      return;
    }

    const {
      id,
      name,
      description,
      priority,
      rate,
      mustHave,
      isActive,
      tdsCampaignsIn,
      tdsCampaignsOut,
      conditions,
      dryModeRate,
      createdBy,
      updatedBy,
    } = yield select((state) => getFormData(state, GENERAL_FILTER_FORM));

    const body = {
      id,
      name,
      description,
      priority,
      tdsCampaignsIn,
      tdsCampaignsOut: tdsCampaignsOut.map((c) => ({ id: c.id, weight: c.weight })),
      conditions: conditions.toJS().byId,
      dryModeRate,
      isActive,
      mustHave,
      rate: Number(rate),
      ...(!id && { createdBy }),
      ...(id && { updatedBy }),
    };

    if (id) {
      yield call(updateGeneralFilter, body, id);
    }
    else {
      yield call(createGeneralFilter, body);
    }
    ExternalRedirect({ to: "/generalFilter/list" });
  }
  catch (err) {
    if (err.isAxiosError
      && err.response
      && err.response.data
      && err.response.data.errors) {
      yield put(setErrors(prepareFetchErrors(err.response.data.errors)));
      yield put(addNotification({ message: err.errorMessage || "Failed to save", severity: "error" }));
    }
    else {
      yield put(addNotification({ message: err.errorMessage || "Failed to save", severity: "error" }));
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
  finally {
    yield put(setLoading(SAVE_GENERAL_FILTER_SAGA, false));
  }
}

export default function* watchSaveGeneralFilter(): Saga<void> {
  yield takeEvery(SAVE_GENERAL_FILTER_SAGA, (makeFetch: Function));
}
