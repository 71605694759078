// @flow
import {
  takeEvery,
  put,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { deleteInFormData, updateInFormData } from "@fas/ui-framework/lib/redux/actions/form";
import { List } from "immutable";
import { removeError } from "@fas/ui-framework/lib/redux/actions/errors";
import { GENERAL_FILTER_FORM, REMOVE_GENERAL_FILTER_TARGETING_GROUP_SAGA } from "../../helpers/constants";
import { getTargetingGroupById, type GroupState } from "../../selectors/generalFilterV2/index";
import type { RemoveGeneralFilterTargetingGroupSaga } from "../../actions/generalFilters";
import { removeGeneralFilterTargetingGroupSaga } from "../../actions/generalFilters";

export function* removeGroup(action: RemoveGeneralFilterTargetingGroupSaga): Saga<void> {
  const { id, parentId }: RemoveGeneralFilterTargetingGroupSaga = action;
  const group: GroupState = yield select(getTargetingGroupById, id);

  if (group.type === "group") {
    // eslint-disable-next-line guard-for-in
    for (let idx: number = 0; group.targetings.length > idx; idx++) {
      yield put(removeGeneralFilterTargetingGroupSaga(group.targetings[idx], parentId));
    }
  }
  yield put(deleteInFormData([GENERAL_FILTER_FORM, "conditions", "byId", id]));
  yield put(updateInFormData([GENERAL_FILTER_FORM, "conditions", "byId", parentId, "targetings"], (targeting: List<string>): List<string> => targeting.filter((targetingId: string): boolean => id !== targetingId)));
  yield put(updateInFormData([GENERAL_FILTER_FORM, "conditions", "allIds"], (ids: List<string>): List<string> => ids.filter((i: string): boolean => id !== i)));
  yield put(removeError(["targetings", id]));
}

export default function* watch(): Saga<void> {
  yield takeEvery(REMOVE_GENERAL_FILTER_TARGETING_GROUP_SAGA, removeGroup);
}
