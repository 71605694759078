// @flow
import Joi from "@hapi/joi";
import BaseTargeting from "./Base";

class TdsSO extends BaseTargeting {
  getJoiSchemaValidation() {
    return Joi.object({
      name: Joi.string(),
      operator: Joi.string(),
      type: Joi.string(),
      parentId: Joi.number(),
      value: Joi.array().min(1).items(
        Joi.alternatives().try(
          Joi.string().pattern(/^[0-9]+$/, "must be a number"),
          Joi.object({ label: Joi.string(), value: Joi.string() })
        )
      ),
    });
  }
}

export default TdsSO;
