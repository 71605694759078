// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import Immutable, { Map } from "immutable";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import {
  tableReducer as tables,
  initTableState,
  type TableState,
} from "@fas/ui-framework/lib/redux/reducers/table";
import loading, {
  initLoadingState,
  type State as LoadingState,
} from "@fas/ui-framework/lib/redux/reducers/loading";
import notifications, {
  initNotificationsState,
  type State as NotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { Actions as TableActions } from "@fas/ui-framework/lib/redux/actions/table";
import type { Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";
import { initState as initErrorsState, reducer as errors } from "@fas/ui-framework/lib/redux/reducers/errors/reducer";
import type { State as ErrorsState } from "@fas/ui-framework/lib/redux/reducers/errors/reducer";
import type { Actions as ErrorActions } from "@fas/ui-framework/lib/redux/actions/errors/actions";
import form, { initFormState, type State as FormState } from "@fas/ui-framework/lib/redux/reducers/form";
import type { Actions as FormActions } from "@fas/ui-framework/lib/redux/actions/form";
import modals, { initModalsState } from "@fas/ui-framework/lib/redux/reducers/modals";
import { type DefaultState as modalsState } from "@fas/ui-framework/lib/redux/reducers/modals/reducer";
import dropdowns, { initDropdownState } from "@fas/ui-framework/lib/redux/reducers/dropdowns";
import type { DropdownActions } from "@fas/ui-framework/lib/redux/actions/dropdowns";
import type { DropdownsState } from "@fas/ui-framework/lib/redux/reducers/dropdowns/types";
import type { Actions as SmartlinkAggregatorsActions } from "../../actions/smartlinkAggregators";
import type { Actions as GeneralFilterActions } from "../../actions/generalFilters";
import type { Actions as HistoryReplaceActions } from "../../actions/historyReplace";
import type { SmartlinkAggregatorsLoadingTypes } from "../../selectors/smartlinkAggregators";
import {
  SMARTLINK_AGGREGATORS_TABLE,
  SMARTLINK_REDIRECT_FORM,
  GENERAL_FILTER_TABLE,
  GENERAL_FILTERS_CAMPAIGNS_TABLE,
  GENERAL_FILTER_CAMPAIGNS_IN_MODAL,
  GENERAL_FILTER_CAMPAIGNS_OUT_MODAL,
  HISTORY_REPLACE_TABLE,
  HISTORY_REPLACE_FORM,
  SMARTLINK_AGGREGATORS_TO_CAMPAIGNS_TABLE,
  GENERAL_FILTER_FORM,
} from "../../helpers/constants";
import { initialState as initPlaceholdersState, type PlaceholdersState } from "../../reducers/placeholders/reducer";
import dictionaries, { initDictionariesState, type State as DictionaryStateType } from "../../reducers/dictionaries";
import backupReducer from "../../reducers/backup";
import placeholders from "../../reducers/placeholders";
import type { PlaceholdersActionType } from "../../actions/placeholders";
import mainSaga from "./saga";
import { defaultPreset as defaultHistoryReplacePreset } from "../../containers/HistoryReplaceActions/presets";

export type State= $ReadOnly<{|
  tables: TableState,
  loading: LoadingState<SmartlinkAggregatorsLoadingTypes>,
  notifications: NotificationsState,
  form: FormState,
  errors: ErrorsState,
  placeholders: PlaceholdersState,
  dictionaries: DictionaryStateType,
  modals: modalsState<string>,
  dropdowns: DropdownsState<string, number | string>,
|}>

export function mapVariablesListToState(): State {
  return {
    tables: initTableState({
      [SMARTLINK_AGGREGATORS_TABLE]: {
        pageSize: 20,
        sorting: {
          "updatedAt": "desc",
        },
      },
      [GENERAL_FILTER_TABLE]: {
        pageSize: 20,
      },
      [GENERAL_FILTERS_CAMPAIGNS_TABLE]: { pageSize: 20 },
      [HISTORY_REPLACE_TABLE]: {
        filters: {
          dateFrom: defaultHistoryReplacePreset.start,
          dateTo: defaultHistoryReplacePreset.end,
        },
        pageSize: 20,
      },
      [SMARTLINK_AGGREGATORS_TO_CAMPAIGNS_TABLE]: {
        pageSize: 20,
        sorting: {
          "createdAt": "desc",
        },
      },
    }),
    form: initFormState({
      [SMARTLINK_REDIRECT_FORM]: {
        smartlinkId: "",
        name: "",
        type: "",
        placeholders: "",
        isActive: false,
      },
      [HISTORY_REPLACE_FORM]: {
        "campaignId": "",
        "lpNew": "",
        "lpNewName": "",
        "lpOld": "",
        "lpOldName": "",
        "layerId": 1,
        "cronus": false,
        "brand": "",
        "pHash": "",
        "pHashOld": "",
        "urlCampaign": "",
        "splitName": "",
        "funnelId": 1,
        "urlFunnel": "",
        "email": "",
        "status": "",
        "locale": [],
        "platform": "",
        "safeMode": "",
        "niche": "",
        "s1": [],
        "errorSearch": "",
        "statusReplace": 200,
        "messageReplace": "",
        "errorsReplace": "",
        "createdAt": "",
      },
      [GENERAL_FILTER_FORM]: {
        id: null,
        name: "",
        priority: null,
        oldPriority: null,
        rate: 0,
        fact: 0,
        dryModeRate: 0,
        description: "",
        mustHave: false,
        isActive: false,
        tdsCampaignsIn: [],
        tdsCampaignsOut: [],
        conditions: Map({
          byId: Map({
            "1": Map({
              type: "group",
              groupOperator: "AND",
              targetings: [
              ],
              parentId: null,
            }),
          }),
          allIds: ["1"],
        }),
      },
    }),
    loading: initLoadingState(),
    notifications: initNotificationsState(),
    errors: initErrorsState(),
    placeholders: initPlaceholdersState,
    dictionaries: initDictionariesState(),
    modals: initModalsState({
      [GENERAL_FILTER_CAMPAIGNS_IN_MODAL]: false,
      [GENERAL_FILTER_CAMPAIGNS_OUT_MODAL]: false,
    }),
    dropdowns: initDropdownState({
      priorityListGF: [],
      split: [
        { label: "a", value: "a" },
        { label: "b", value: "b" },
        { label: "c", value: "c" },
        { label: "d", value: "d" },
        { label: "e", value: "e" },
        { label: "f", value: "f" },
      ],
      campaignStatus: [
        { label: "Yes", value: 1 },
        { label: "No", value: 0 },
      ],
      offerStatus: [
        { label: "Yes", value: 1 },
        { label: "No", value: 0 },
      ],
      offerInCampaignStatus: [
        { label: "Yes", value: 1 },
        { label: "No", value: 0 },
      ],
      englishSpeakingCountries: [],
      commercialCountries: [],
    }),
  };
}

export const initialState = mapVariablesListToState();

export type Actions = TableActions
  | FormActions<typeof SMARTLINK_REDIRECT_FORM | typeof HISTORY_REPLACE_FORM>
  | ErrorActions
  | LoadingActions<SmartlinkAggregatorsLoadingTypes>
  | SmartlinkAggregatorsActions
  | GeneralFilterActions
  | HistoryReplaceActions
  | PlaceholdersActionType
  | NotificationsActions
  | DropdownActions<string, string | number>;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  tables,
  loading,
  notifications,
  form,
  errors,
  placeholders,
  dictionaries,
  modals,
  dropdowns,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line no-underscore-dangle, flowtype/require-variable-type
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    backupReducer(reducers),
    initialState,
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
