// @flow
/* eslint-disable import/max-dependencies */
import React, { type Node } from "react";
import isEqual from "lodash.isequal";
import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
} from "@mui/material";
import {
  DeleteOutlineTwoTone as DeleteOutlineTwoToneIcon,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import type { DropdownItem, Option } from "@fas/ui-framework/lib/redux/reducers/dropdowns/types";
import { GridUc } from "@fas/ui-core";
import type {
  SelectedTriggerRule,
  RuleTriggerQb,
  TriggerValueType,
} from "../../selectors/antifraudTrigger";
import { TRIGGER_VALUE_TYPE, TRIGGER_FEATURE_TYPE } from "../../helpers/constants";

export type Classes = {
  root: string,
};

const staticOperators: Array<string> = ["exists", "absent"];

export type Props = {
  id: string,
  parentGroupId: string,
  selectedRule: SelectedTriggerRule,
  isDisabledRule?: boolean,
  isRemoveDisabled?: boolean,

  operatorList: Array<Option<string>>,
  valuesList: Array<Option<string>>,
  ruleList: Array<RuleTriggerQb>,

  handleChangeValue: (string, string) => void,
  handleChangeValueType: (string, TriggerValueType) => void,
  handleChangeRule: (string, RuleTriggerQb) => void,
  handleChangeOperator: (string, string) => void,
  handleRemoveRule: (string, string) => void,
  handleChangeLocation: (string, "data" | "headers" | "cookies") => void,

  errorRule?: { message: string } | null,
  errorValue?: { message: string } | null,
  errorOperator?: { message: string } | null,
};

const useStyles: () => Classes = makeStyles(() => ({
  root: {
    whiteSpace: "nowrap",
    textDecoration: "underline",
    color: "#148EFF",
    fontSize: "12px",
  },
}));

function TargetingRuleComponent(props: Props): Node {
  const {
    id,
    parentGroupId,
    selectedRule,
    operatorList,
    ruleList,
    valuesList,

    handleChangeValue,
    handleChangeValueType,
    handleChangeRule,
    handleChangeOperator,
    handleRemoveRule,
    handleChangeLocation,
    errorRule = null,
    errorValue = null,
    errorOperator = null,
  }: Props = props;

  const classes: Classes = useStyles();
  return (
    <GridUc container alignItems="flex-start" data-testid="targeting-qb-row" mb={2} spacing={2}>
      <Grid item xs={1} data-testid="trigger-location">
        <TextField
          data-testid="trigger-location-input"
          fullWidth
          select
          label="Location"
          variant="outlined"
          type="string"
          size="small"
          value={selectedRule.location}
          onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
            // $FlowFixMe
            handleChangeLocation(id, e.target.value);
          }}
        >
          <MenuItem value="data">Data</MenuItem>
          <MenuItem value="headers">Headers</MenuItem>
          <MenuItem value="cookies">Cookies</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={3} data-testid="trigger-rule">
        <Autocomplete
          data-testid="trigger-rule-input"
          size="small"
          options={ruleList}
          getOptionLabel={(option: RuleTriggerQb): string => option.label || ""}
          renderInput={(params): Node => (
            <TextField
              {...params}
              label="Name"
              error={!!errorRule}
              helperText={errorRule && errorRule.message}
              fullWidth
              variant="outlined"
            />
          )}
          value={selectedRule}
          onChange={(_, value: RuleTriggerQb) => {
            handleChangeRule(id, value);
          }}
          isOptionEqualToValue={(option, value): boolean => option.value === value.name}
          disableClearable
        />
      </Grid>
      <Grid item xs={1} data-testid="trigger-operator">
        <TextField
          data-testid="trigger-operator-input"
          fullWidth
          select
          disabled={!selectedRule.name}
          label="Operator"
          variant="outlined"
          type="string"
          size="small"
          value={selectedRule.operator}
          onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
            handleChangeOperator(id, e.target.value);
          }}
          error={!!errorOperator}
          helperText={errorOperator && errorOperator.message}
        >
          {operatorList.map(({ value, label }: DropdownItem<string>): Node => (
            <MenuItem key={value} value={value}>{label}</MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={3} data-testid="trigger-value">
        {selectedRule.valueType === TRIGGER_FEATURE_TYPE && (
          <Autocomplete
            data-testid="trigger-value-input"
            size="small"
            disabled={!selectedRule.name || staticOperators.includes(selectedRule.operator)}
            options={valuesList}
            getOptionLabel={(option: DropdownItem<string>): string => option.label || ""}
            renderInput={(params): Node => (
              <TextField
                {...params}
                label="Dictionary"
                error={!!errorValue}
                helperText={errorValue && errorValue.message}
                fullWidth
                variant="outlined"
              />
            )}
            value={valuesList.find(({ value }: DropdownItem<string>) => value === selectedRule.value) || ""}
            onChange={(e, { value }: DropdownItem<string>) => {
              handleChangeValue(id, value);
            }}
            isOptionEqualToValue={(option, value): boolean => option.value === value}
            disableClearable
          />
        )}
        {selectedRule.valueType === TRIGGER_VALUE_TYPE && (
          <TextField
            data-testid="trigger-value-input"
            fullWidth
            disabled={!selectedRule.name || staticOperators.includes(selectedRule.operator)}
            label="Value"
            variant="outlined"
            type="string"
            size="small"
            value={selectedRule.value}
            onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
              handleChangeValue(id, e.target.value);
            }}
            error={!!errorValue}
            helperText={errorValue && errorValue.message}
          />
        )}
      </Grid>
      <Grid item xs={3} data-testid="trigger-value-type">
        <RadioGroup
          data-testid="trigger-value-type-input"
          row
          name="type"
          value={selectedRule.valueType}
          onChange={(_: *, newValue: TriggerValueType) => {
            handleChangeValueType(id, newValue);
          }}
        >
          <FormControlLabel
            key={TRIGGER_VALUE_TYPE}
            value={TRIGGER_VALUE_TYPE}
            control={<Radio color="primary" />}
            label="Value"
            disabled={!selectedRule.name || staticOperators.includes(selectedRule.operator)}
          />
          <FormControlLabel
            key={TRIGGER_FEATURE_TYPE}
            value={TRIGGER_FEATURE_TYPE}
            control={<Radio color="primary" />}
            label="Dictionary"
            disabled={!selectedRule.name || staticOperators.includes(selectedRule.operator)}
          />
        </RadioGroup>
      </Grid>
      <Grid item xs={1} data-testid="trigger-remove-rule">
        <IconButton
          data-testid="trigger-remove-rule-icon"
          onClick={() => {
            handleRemoveRule(id, parentGroupId);
          }}
          color="primary"
          className={classes.root}
        >
          <DeleteOutlineTwoToneIcon />
        </IconButton>
      </Grid>
    </GridUc>
  );
}

export default React.memo<Props>(TargetingRuleComponent, isEqual);
