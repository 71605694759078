// @flow
import * as React from "react";
import { Grid, Button } from "@mui/material";
import Row from "./Row";
import type {
  Props,
} from "./types/SplitsDistribution.types";
import WeightBody from "./WeightBody";
import FrequencyBody from "./FrequencyBody";
import Can from "../Can";

const makeTestAttributeByName = (name?: string = "default", suffix?: string = "component"): string => {
  const transformedName = name.toLowerCase().replace(" ", "-");
  return `${transformedName}-${suffix}`;
};

const isSelected = (selected: string, current: string) => (selected.toLowerCase() === current.toLowerCase() ? "primary" : "inherit");

const SplitsDistribution = ({
  period = "",
  periods = ["24h", "48h", "72h", "96h", "120h"],
  selectBy = "weights",
  selectionOptions = [
    {
      label: "Weights",
      permissions: [],
    }, {
      label: "Frequency",
      permissions: [],
    }, {
      label: "EPC",
      permissions: ["unitedReborn.field.splitEPC"],
    },
  ],
  splits,
  errors,
  defaultSplit,
  onChangeSelection,
  onPeriodChange,
  onSelectDefaultSplit,
  onSplitWeightChange,
  onChangeSplitFrequency,
  onSplitWeightsEqualShares,
}: Props) => {
  let body = <></>;

  if (selectBy === "weights") {
    body = (
      <WeightBody
        splits={splits}
        onWeightChange={onSplitWeightChange}
        errors={errors}
        onSplitWeightsEqualShares={onSplitWeightsEqualShares}
      />
    );
  }
  if (selectBy === "frequency") {
    body = (
      <FrequencyBody
        // $FlowFixMe
        period={period}
        // $FlowFixMe
        periods={periods}
        // $FlowFixMe
        onPeriodChange={onPeriodChange}
        splits={splits}
        defaultSplit={defaultSplit}
        onSelectDefault={(e) => onSelectDefaultSplit(e.target.innerText.toLowerCase())}
        onChangeFrequency={onChangeSplitFrequency}
        errors={errors}
      />
    );
  }

  return (
    <Grid container>
      <Row title="Method">
        {// $FlowFixMe
          selectionOptions.map(({ label, permissions }) => (
            <Can permissions={permissions} key={label}>
              <Button
                variant="contained"
                // $FlowFixMe
                color={isSelected(selectBy, label)}
                value={label.toLowerCase()}
                onClick={(e) => onChangeSelection(e.target.innerText.toLowerCase())}
                data-testid={makeTestAttributeByName(label, "button")}
              >
                {label}
              </Button>
            </Can>
          ))
        }
      </Row>
      {body}
    </Grid>
  );
};

export default SplitsDistribution;
