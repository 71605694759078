// @flow

import type { Presets, Preset } from "@fas/ui-core/lib/DateRangePickerV2/DateRangePickerV2.types";
import moment from "moment";
import "moment/locale/en-gb";

moment.locale("en-gb");

// eslint-disable-next-line import/prefer-default-export
export const presets: Presets = [
  {
    label: "Today",
    value: "today",
    start: moment().format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  },
  {
    label: "Yesterday",
    value: "yesterday",
    start: moment().subtract(1, "day").format("YYYY-MM-DD"),
    end: moment().subtract(1, "day").format("YYYY-MM-DD"),
  },
  {
    label: "This week",
    value: "thisWeek",
    start: moment().startOf("week").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  },
  {
    label: "Past week",
    value: "pastWeek",
    start: moment().subtract(1, "week").startOf("week").format("YYYY-MM-DD"),
    end: moment().subtract(1, "week").endOf("week").format("YYYY-MM-DD"),
  },
  {
    label: "Last 7 days",
    value: "last7day",
    start: moment().subtract(6, "day").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  },
  {
    label: "This month",
    value: "thisMonth",
    start: moment().startOf("month").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  },
  {
    label: "Last month",
    value: "lastmonth",
    start: moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
    end: moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
  },
  {
    label: "Last 30 days",
    value: "last30day",
    start: moment().subtract(29, "day").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  },
];

export const defaultPreset: Preset = presets[4];

export const minDate: string = moment(new Date()).subtract(2, "year").format("YYYY-MM-DD");
export const maxDate: string = moment(new Date()).format("YYYY-MM-DD");
