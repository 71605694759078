// @flow
import Joi from "joi";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";

const schema = Joi.object({
  name: Joi.string().max(100).required(),
  description: Joi.string().allow("").max(2048),
  priority: Joi.number().integer().min(1),
  rate: Joi
    .number()
    .integer()
    .min(1)
    .max(100)
    .required()
    .error(() => ({ message: "Invalid % Traffic" })),
  dryModeRate: Joi.number().integer().min(0).max(100)
    .required(),
}).messages({
  "number.base": "This field is required",
  "string.empty": "This field is required",
}).options({ stripUnknown: true });

export default function (values: Object): ErrorsMapper {
  const { error: { details = {} } = {} } = schema.validate(values, { abortEarly: false });
  return Object.keys(details).reduce((acc: ErrorsMapper, key: string) => ({
    ...acc,
    [details[key].path.join(".")]: { message: details[key].message },
  }), {});
}
