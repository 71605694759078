// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { saveAs } from "file-saver";
import type { GeneralFilterListItem } from "../GeneralFilterList/type";

type Props = {
  data: GeneralFilterListItem[],
  disabled: boolean,
};

const ExportCSVButton: StatelessFunctionalComponent<Props> = ({
  data,
  disabled,
}: Props) => {
  const exportToCSV: () => void = () => {
    const timestamp = Date.now();
    const filename = `generalFilter_${timestamp}.csv`;

    const prepareData = data.map((row: GeneralFilterListItem) => ({
      id: row.id,
      name: row.name,
      tdsCampaignsOut: row.tdsCampaignsOut.length ? row.tdsCampaignsOut.reduce((acc, { weight, id: tdsId }) => {
        acc.push(`${tdsId} - ${weight}`);
        return acc;
      }, []).join(", ") : "",
      rate: row.rate,
      priority: row.priority,
      description: row.description,
      dryModeRate: row.dryModeRate,
      fact: row.fact,
      isActive: row.isActive ? "Active" : "Inactive",
      mustHave: row.mustHave ? "Yes" : "No",
      createdAt: row.createdAt,
      updatedAt: row.updatedAt,
      updatedBy: row.updatedBy,
    }));

    const headers = Object.keys(prepareData[0]) || [];
    const csvContent = [
      headers.join(","),
      ...prepareData.map((row) => headers.map((fieldName) => JSON.stringify(row[fieldName] || "")).join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, filename);
  };

  return (
    <Button
      onClick={exportToCSV}
      data-testid="export-button"
      variant="contained"
      color="primary"
      disabled={disabled}
    >
      <FileDownloadIcon />
      Export
    </Button>
  );
};

export default ExportCSVButton;
