// @flow
import React from "react";
import {
  Box, Typography,
} from "@mui/material";
import { ListWithGroups, SelectCustom } from "@fas/ui-core";
import ColumnItemCard from "@fas/ui-core/lib/ColumnItemCard";
import type { DropResult } from "@hello-pangea/dnd";
import type { Group } from "@fas/ui-core/lib/ListWithGroups/ListWithGroups.types";
import DragList from "./DragList";
import type { FilterItem } from "./types/ConfigureColumnsComponent.types";

type Props = {
  groupBy: string,
  groupByList: Array<{value: string, title: string}>,
  onChangeGroupBy: (string) => void,
  groups: Array<Group>,
  onSelectAllFilters: (boolean) => void,
  requireFilters: FilterItem[],
  selectedFilters: FilterItem[],
  onFilterClick: ({
    groupName: string,
    groupIndex: number,
    title: string,
    index: number,
    value: boolean,
  }) => void,
  onFilterDragEnd: (DropResult) => void,
};

const Body = ({
  groupBy,
  groupByList,
  onChangeGroupBy,
  onSelectAllFilters,
  onFilterClick,
  onFilterDragEnd,
  requireFilters,
  selectedFilters,
  groups,
}: Props) => (
  <Box display="flex" pt={3} minHeight="100%">
    <Box width="100%" overflow="auto" pt={1}>
      <Box px={3}>
        <SelectCustom
          variant="outlined"
          size="small"
          value={groupBy}
          values={groupByList}
          onTypeChange={({ target: { value } }) => onChangeGroupBy(value)}
          title="Group by"
        />
      </Box>
      <Box height="440px">
        <ListWithGroups
          withSearch={false}
          groups={groups}
          isAllSelected={groups.every((group) => group.filters.every(({ selected }) => selected))}
          onItemClick={onFilterClick}
          toggleAll={onSelectAllFilters}
        />
      </Box>
    </Box>
    <Box width="100%" overflow="auto" pt={1}>
      <Box p={1}>
        <Typography variant="h5" gutterBottom>
          Report Columns
        </Typography>
      </Box>
      <DragList
        items={requireFilters}
        renderItem={(item) => (
          <Box p={1}>
            <ColumnItemCard
              item={item}
              name={item.title}
              count={item.filter.length}
              disabled
            />
          </Box>
        )}
      />
      <DragList
        items={selectedFilters}
        renderItem={(item) => (
          <Box p={1}>
            <ColumnItemCard
              item={item}
              name={item.title}
              count={item.filter.length}
              disabled
            />
          </Box>
        )}
        onDragEnd={onFilterDragEnd}
      />
    </Box>
  </Box>
);

export default Body;
