// @flow
/* eslint-disable import/max-dependencies */
import React, {
  useEffect,
  useState,
  type StatelessFunctionalComponent,
} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { mtuDefaultTheme as theme } from "@fas/ui-themes";
import environment from "environment";
import { Error, Loader, AccessDenied } from "@fas/ui-core";
import { userApiService } from "../../services/userApi";
import Smartlink from "../../pages/Smartlink";
import GeneralFilter from "../../pages/GeneralFilter"; // FIX 92090
import GeneralFilterList from "../../pages/GeneralFilterList";
import GeneralFilterForm from "../../pages/GeneralFilterForm";
import FunnelTemplate from "../../pages/FunnelTemplate";
import "./App.css";
import PrivateRoute from "./PrivateRoute";
import AppHeader from "../AppHeader";
import FunnelTemplates from "../../pages/FunnelTemplates";
import FunnelsTemplateList from "../../pages/FunnelsTemplateIndex";
import FunnelTemplateReport from "../../pages/FunnelTemplateReport";
import AntifraudTriggerList from "../../pages/AntifraudTriggerList";
import AntifraudDictionaries from "../../pages/AntifraudDictionaries";
import AntifraudTriggerForm from "../../pages/AntifraudTriggerForm";
import AntifraudDictionaryForm from "../../pages/AntifraudDictionaryForm";
import AntifraudScenarioForm from "../../pages/AntifraudScenarioForm";
import AntifraudScenariosList from "../../pages/AntifraudScenariosList";
import AntifraudVariablesList from "../../pages/AntifraudVariablesList";
import AntifraudVariableForm from "../../pages/AntifraudVariableForm";
import FrequencyCupForJumps from "../../pages/FrequencyCupForJumps";
import SmartlinkAggregators from "../../pages/SmartlinkAggregators";
import SmartlinkAggregatorForm from "../../pages/SmartlinkAggregatorForm";
import HistoryReplaceList from "../../pages/HistoryReplaceList";
import HistoryReplaceForm from "../../pages/HistoryReplaceForm";
import SmartlinkAggregatorToCampaign from "../../pages/SmartlinkAggregatorToCampaign";
import AppStoreWrapper from "../../pages/AppStoreWrapper";
import IndexPage from "../../pages/IndexPage";

import "typeface-roboto";

const App: StatelessFunctionalComponent<{}> = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userApiService.getUserInfo(environment.endpoints.get.apiKey)
      .then(() => setLoading(false))
      .catch(() => {});
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <AppHeader />
          {loading && <Loader loading size={35} mt={37} />}
          {!loading && (
            <>
              <Routes>
                <Route path="/" element={<IndexPage />} />

                <Route
                  path="/smartlink"
                  element={<PrivateRoute permissions={["/unitedReborn/v2/smartlink/create"]} component={Smartlink} />}
                />
                <Route
                  path="/smartlink/:id"
                  element={<PrivateRoute permissions={["/unitedReborn/v2/smartlink/edit"]} component={Smartlink} />}
                />

                <Route element={<AppStoreWrapper />}>
                  <Route
                    path="/smartlinkAggregators/list"
                    element={(
                      <PrivateRoute
                        permissions={[{ path: "/api/v1/smartlinkAggregators/list", method: "POST" }]}
                        component={SmartlinkAggregators}
                      />
                    )}
                  />
                  <Route
                    path="/smartlinkAggregators"
                    element={(
                      <PrivateRoute
                        permissions={[
                          { path: "/api/v1/smartlinkAggregators", method: "GET" },
                          { path: "/api/v1/smartlinkAggregators", method: "POST" },
                          { path: "/api/v1/smartlinkAggregators", method: "PUT" },
                        ]}
                        component={SmartlinkAggregatorForm}
                      />
                    )}
                  />
                  <Route
                    path="/smartlinkAggregators/campaigns"
                    element={(
                      <PrivateRoute
                        permissions={[
                          { path: "/api/v1/dictionary/smartlinkAggregatorsToCampaign/dropdown", method: "GET" },
                          { path: "/api/v1/template/smartlinkAggregatorsToCampaign/filter", method: "POST" },
                        ]}
                        component={SmartlinkAggregatorToCampaign}
                      />
                    )}
                  />
                  <Route
                    path="/smartlinkAggregators/:id"
                    element={(
                      <PrivateRoute
                        permissions={[
                          { path: "/api/v1/smartlinkAggregators", method: "GET" },
                          { path: "/api/v1/smartlinkAggregators", method: "POST" },
                          { path: "/api/v1/smartlinkAggregators", method: "PUT" },
                        ]}
                        component={SmartlinkAggregatorForm}
                      />
                    )}
                  />

                  <Route
                    path="/historyReplace/list"
                    element={<PrivateRoute permissions={[{ path: "/api/v1/historyReplaceLP/list", method: "POST" }]} component={HistoryReplaceList} />}
                  />
                  <Route
                    path="/historyReplace/view/:id"
                    element={<PrivateRoute permissions={[{ path: "/api/v1/historyReplaceLP", method: "GET" }]} component={HistoryReplaceForm} />}
                  />

                  <Route
                    path="/generalFilter/list"
                    element={(
                      <PrivateRoute
                        permissions={[
                          { path: "/api/v1/generalFilterV2/list", method: "POST" },
                        ]}
                        component={GeneralFilterList}
                      />
                    )}
                  />
                  <Route
                    path="/generalFilter/:id"
                    element={(
                      <PrivateRoute
                        permissions={[
                          { path: "/api/v1/generalFilter", method: "POST" },
                        ]}
                        component={GeneralFilterForm}
                      />
                    )}
                  />
                  <Route
                    path="/generalFilter/create"
                    element={(
                      <PrivateRoute
                        permissions={[{ path: "/api/v1/generalFilter", method: "POST" }]}
                        component={GeneralFilterForm}
                      />
                    )}
                  />
                  <Route
                    path="/generalFilter/cloneV2/:id"
                    element={(
                      <PrivateRoute
                        permissions={[{ path: "/api/v1/generalFilter", method: "POST" }]}
                        component={GeneralFilterForm}
                      />
                    )}
                  />
                </Route>

                <Route path="/generalFilter/edit/:id" element={<GeneralFilter />} />
                <Route path="/generalFilter/clone/:id" element={<GeneralFilter />} />
                <Route path="/generalFilter/add" element={<GeneralFilter />} />

                <Route
                  path="/funnelTemplates"
                  element={<PrivateRoute permissions={["/dictionary/funnelTemplates/create"]} component={FunnelTemplates} />}
                />
                <Route
                  path="/funnelTemplates/list"
                  element={<PrivateRoute permissions={["/dictionary/funnelTemplates/list"]} component={FunnelsTemplateList} />}
                />
                <Route
                  path="/funnelTemplates/view/:id/"
                  element={<PrivateRoute permissions={["/dictionary/funnelTemplates/view"]} component={FunnelTemplate} />}
                />
                <Route
                  path="/funnelTemplates/view/:id/:tab"
                  element={<PrivateRoute permissions={["/dictionary/funnelTemplates/view"]} component={FunnelTemplate} />}
                />
                <Route
                  path="/funnelTemplates/report"
                  element={<PrivateRoute permissions={["/dictionary/funnelTemplates/report"]} component={FunnelTemplateReport} />}
                />
                <Route
                  path="/funnelTemplates/:id"
                  element={<PrivateRoute permissions={["/dictionary/funnelTemplates/create"]} component={FunnelTemplates} />}
                />

                <Route
                  path="/antifraudTriggers"
                  element={(
                    <PrivateRoute
                      permissions={[
                        { path: "/api/v1/frodi/triggers", method: "GET" },
                        { path: "/api/v1/frodi/triggers", method: "POST" },
                        { path: "/api/v1/frodi/triggers", method: "PUT" },
                      ]}
                      component={AntifraudTriggerForm}
                    />
                  )}
                />
                <Route
                  path="/antifraudTriggers/list"
                  element={<PrivateRoute permissions={[{ path: "/api/v1/frodi/triggers/list", method: "GET" }]} component={AntifraudTriggerList} />}
                />
                <Route
                  path="/antifraudTriggers/:id"
                  element={(
                    <PrivateRoute
                      permissions={[
                        { path: "/api/v1/frodi/triggers", method: "GET" },
                        { path: "/api/v1/frodi/triggers", method: "POST" },
                        { path: "/api/v1/frodi/triggers", method: "PUT" },
                      ]}
                      component={AntifraudTriggerForm}
                    />
                  )}
                />

                <Route
                  path="/antifraudDictionaries"
                  element={(
                    <PrivateRoute
                      permissions={[
                        { path: "/api/v1/frodi/dictionaries", method: "GET" },
                        { path: "/api/v1/frodi/dictionaries", method: "POST" },
                        { path: "/api/v1/frodi/dictionaries", method: "PUT" },
                      ]}
                      component={AntifraudDictionaryForm}
                    />
                  )}
                />
                <Route
                  path="/antifraudDictionaries/list"
                  element={(
                    <PrivateRoute
                      permissions={[{ path: "/api/v1/frodi/dictionaries/list", method: "GET" }]}
                      component={AntifraudDictionaries}
                    />
                  )}
                />
                <Route
                  path="/antifraudDictionaries/:id"
                  element={(
                    <PrivateRoute
                      permissions={[
                        { path: "/api/v1/frodi/dictionaries", method: "GET" },
                        { path: "/api/v1/frodi/dictionaries", method: "POST" },
                        { path: "/api/v1/frodi/dictionaries", method: "PUT" },
                      ]}
                      component={AntifraudDictionaryForm}
                    />
                  )}
                />

                <Route
                  path="/antifraudScenarios/list"
                  element={(
                    <PrivateRoute
                      permissions={[{ path: "/api/v1/frodi/scenarios/list", method: "GET" }]}
                      component={AntifraudScenariosList}
                    />
                  )}
                />
                <Route
                  path="/antifraudScenarios"
                  element={(
                    <PrivateRoute
                      permissions={[{ path: "/api/v1/frodi/scenarios", method: "POST" }]}
                      component={AntifraudScenarioForm}
                    />
                  )}
                />
                <Route
                  path="/antifraudScenarios/:id"
                  element={(
                    <PrivateRoute
                      permissions={[{ path: "/api/v1/frodi/scenarios", method: "POST" }]}
                      component={AntifraudScenarioForm}
                    />
                  )}
                />

                <Route
                  path="/antifraudVariables"
                  element={(
                    <PrivateRoute
                      permissions={[{ path: "/api/v1/frodi/variables", method: "POST" }]}
                      component={AntifraudVariableForm}
                    />
                  )}
                />
                <Route
                  path="/antifraudVariables/list"
                  element={(
                    <PrivateRoute
                      permissions={[{ path: "/api/v1/frodi/variables/list", method: "GET" }]}
                      component={AntifraudVariablesList}
                    />
                  )}
                />
                <Route
                  path="/antifraudVariables/:id"
                  element={(
                    <PrivateRoute
                      permissions={[{ path: "/api/v1/frodi/variables", method: "POST" }]}
                      component={AntifraudVariableForm}
                    />
                  )}
                />

                <Route
                  path="/frequencyCapForJumps"
                  element={(
                    <PrivateRoute
                      permissions={[{ path: "/api/v1/jumpsForFrequencyCap", method: "PUT" }]}
                      component={FrequencyCupForJumps}
                    />
                  )}
                />

                <Route path="/accessDenied" element={<AccessDenied />} />
                <Route path="*" element={<Error />} />
              </Routes>
            </>
          )}
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
