// @flow
import React, { useState } from "react";
import {
  SetupCard, TargetingRuleComponent, TargetingRuleContainer, GroupContainer,
} from "@fas/ui-core";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import type { Props as TargetingRuleProps } from "@fas/ui-core/lib/FlatQueryBuilder/TargetingRule/TargetingRuleComponent";
import type { Node } from "react";
import type { Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import {
  setInFormData, mergeInFormData,
} from "@fas/ui-framework/lib/redux/actions/form";
import { getNextId } from "@fas/ui-framework/lib/services/generators";
import { List } from "immutable";
import { removeError } from "@fas/ui-framework/lib/redux/actions/errors";
import CopyToClipboard from "copy-to-clipboard";
import { useLoading } from "../../hooks";
import {
  FETCH_GENERAL_FILTER_TARGETING_SAGA,
  GENERAL_FILTER_FORM,
  GET_GENERAL_FILTER_SAGA, SAVE_GENERAL_FILTER_SAGA,
} from "../../helpers/constants";
import {
  getLoadingStatusForValues as getLoadingStatusForValuesV2,
  getTargetingGroupById as getTargetingGroupByIdV2,
  getTargetingRuleById as getTargetingRuleByIdV2,
  getTargetingRulesList as getTargetingRulesListV2,
  getTargetingGroupErrorById as getTargetingGroupErrorByIdV2,
  getOperators as getOperatorsV2,
  getValues as getValuesV2,
  getTargetingErrorById as getTargetingErrorByIdV2, getAllIds, getDictionary,
} from "../../selectors/generalFilterV2";
import { fetchTargetingValuesGfSaga } from "../../actions/gfTargeting";
import { removeGeneralFilterTargetingGroupSaga } from "../../actions/generalFilters";
import PasteValuesPopupContainer from "../../containers/PasteValuesPopupContainer/PasteValuesPopupContainerV2";
// eslint-disable-next-line import/max-dependencies
import type { GroupStateJs, RuleStateJs } from "./index";
import type { State } from "../../pages/AppStoreWrapper";

const emptyRule: RuleStateJs = {
  type: "rule",
  name: "",
  operator: "",
  value: List([]),
};
const emptyGroup: GroupStateJs = {
  type: "group",
  groupOperator: "AND",
  targetings: List([]),
};

function GeneralFilterTargeting() {
  const dispatch: <A>(A) => A = useDispatch();

  const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
  const [ruleId, setRuleId] = useState<string>("");

  const closePopup: () => void = () => {
    setRuleId("");
    setPopupOpen(false);
  };
  const handleCopyValue: (string, {label: string, value: string, subValues?: string[]}[]) => void = (id, values) => {
    CopyToClipboard(values.map(({ label }) => label).join(", "));
  };
  const handlePasteValue: (string) => void = (id) => {
    setRuleId(id);
    setPopupOpen(true);
  };

  const isGetTargetinListLoading: boolean = useLoading(FETCH_GENERAL_FILTER_TARGETING_SAGA);
  const isGetFormloading: boolean = useLoading(GET_GENERAL_FILTER_SAGA);
  const isSaveFormloading: boolean = useLoading(SAVE_GENERAL_FILTER_SAGA);
  const allIds: string[] = useSelector((state: State) => getAllIds(state));
  // $FlowFixMe
  const handleChangeGroupOperator = (id: string, val: "OR" | "AND") => {
    dispatch(mergeInFormData([GENERAL_FILTER_FORM, "conditions", "byId", id], { groupOperator: val }));
    dispatch(removeError(["targetings", id]));
  };
  const handleAddRule = (parentId: string) => {
    const nextId = getNextId(allIds);
    dispatch(mergeInFormData([GENERAL_FILTER_FORM, "conditions", "byId", parentId, "targetings"], nextId));
    dispatch(mergeInFormData([GENERAL_FILTER_FORM, "conditions"], { allIds: [...allIds, nextId] }));
    dispatch(mergeInFormData([GENERAL_FILTER_FORM, "conditions", "byId", nextId], { ...emptyRule, parentId }));
    dispatch(removeError(["targetings", parentId]));
  };
  const handleAddGroup = (parentId: string) => {
    const nextId = getNextId(allIds);
    dispatch(mergeInFormData([GENERAL_FILTER_FORM, "conditions", "byId", parentId, "targetings"], nextId));
    dispatch(mergeInFormData([GENERAL_FILTER_FORM, "conditions"], { allIds: [...allIds, nextId] }));
    dispatch(mergeInFormData([GENERAL_FILTER_FORM, "conditions", "byId", nextId], { ...emptyGroup, parentId }));
    dispatch(removeError(["targetings", parentId]));
  };
  const handleChangeOperator = (id: string, val: { name: string, value: string}) => {
    dispatch(mergeInFormData([GENERAL_FILTER_FORM, "conditions", "byId", id], { operator: val.value }));
    dispatch(removeError(["targetings", id]));
  };
  const handleChangeValue = (id: string, val: Option[]) => {
    dispatch(setInFormData([GENERAL_FILTER_FORM, "conditions", "byId", id, "value"], val));
    dispatch(removeError(["targetings", id]));
  };
  const handleChangeRule = (id, rule) => {
    dispatch(setInFormData([GENERAL_FILTER_FORM, "conditions", "byId", id, "name"], rule?.name?.value));
    dispatch(setInFormData([GENERAL_FILTER_FORM, "conditions", "byId", id, "operator"], ""));
    dispatch(setInFormData([GENERAL_FILTER_FORM, "conditions", "byId", id, "value"], List([])));
    if (rule) {
      dispatch(fetchTargetingValuesGfSaga(rule.name?.value));
    }
    dispatch(removeError(["targetings", id]));
  };

  const handleRemoveRuleAndGroup = (id: string, parentId: string) => {
    dispatch(removeGeneralFilterTargetingGroupSaga(id, parentId));
  };

  const engSpeakingCountries: Array<string> = useSelector((state) => getDictionary(state, "englishSpeakingCountries"));
  const commercialCountries: Array<string> = useSelector((state) => getDictionary(state, "commercialCountries"));

  return (
    <SetupCard title="Targeting">
      <Box width="100%">
        <GroupContainer
          id="1"
          isRuleListLoading={isGetTargetinListLoading || isGetFormloading || isSaveFormloading}
          RuleComponent={
            // eslint-disable-next-line no-unused-vars,max-len
            ({ isRemoveDisabled, ...propsRule }: TargetingRuleProps): Node => <TargetingRuleComponent {...propsRule} />
          }
          RuleContainer={TargetingRuleContainer}
          groupProps={{
            getGroupByIdSelector: getTargetingGroupByIdV2,
            changeGroupOperator: handleChangeGroupOperator,
            removeRule: handleRemoveRuleAndGroup,
            getStatusRule: (): boolean => false,
            getErrorMessage: getTargetingGroupErrorByIdV2,
            addGroup: handleAddGroup,
            // $FlowFixMe need fix type at lib
            removeGroup: handleRemoveRuleAndGroup,
            addRule: handleAddRule,
            disabledDelete: true,
          }}
          ruleProps={{
            getRuleByIdSelector: getTargetingRuleByIdV2,
            handleChangeValue,
            handleChangeRule,
            handleChangeOperator,
            isRemoveDisabled: false,
            checkLoadingForValues: getLoadingStatusForValuesV2,
            getRuleList: getTargetingRulesListV2,
            getOperatorsList: getOperatorsV2,
            getValuesList: getValuesV2,
            engSpeakingCountries,
            commercialCountries,
            getErrors: getTargetingErrorByIdV2,
            handleCopyValue,
            handlePasteValue,
          }}
        />
        {isPopupOpen && ruleId && (
          <PasteValuesPopupContainer
            ruleId={ruleId}
            onClose={() => {
              closePopup();
            }}
            onSave={(values) => {
              handleChangeValue(ruleId, values);
              closePopup();
            }}
          />
        )}
      </Box>
    </SetupCard>
  );
}

export default GeneralFilterTargeting;
