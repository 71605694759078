// @flow
import React, { useEffect, useState } from "react";
import { Dialog, Divider } from "@mui/material";
import type { Filter, Group } from "@fas/ui-core/lib/ListWithGroups/ListWithGroups.types";
import Footer from "./Footer";
import Header from "./Header";
import Body from "./Body";
import { dragEndWrapper } from "../../helpers/dndUtil";
import type { FilterItem } from "./types/ConfigureColumnsComponent.types";

const unique = (v, i, a) => a.indexOf(v) === i;

const mapFilter = (item: Filter) => ({
  title: item.title,
  filter: [],
  key: item.filterKey,
});

type Props = {
  open: boolean,
  onApply: ({
    groupBy: string,
    filterOrder: Array<string>,
  }) => void,
  onClose: () => void,
  initialGroupBy?: string,
  initialFilterOrder?: Array<string>,
  initialGroups?: Array<Group>,
  groupByList?: Array<{value: string, title: string}>,
  getRequiredFilterKeyByGroupBy?: (groupBy: string) => Array<string>,
}


const defaultProps = {
  initialGroupBy: "",
  initialFilterOrder: [],
  getRequiredFilterKeyByGroupBy: (groupBy: string): Array<string> => {
    const filtersByGroupByMap = {
      template: ["templateId"],
      smartlink: ["smartlinkId"],
    };
    return filtersByGroupByMap[groupBy] || [];
  },
  initialGroups: [
    {
      title: "Columns",
      filters: [
        {
          title: "ID template",
          selected: false,
          isDisabled: false,
          filterKey: "templateId",
        },
        {
          title: "Name",
          selected: false,
          isDisabled: false,
          filterKey: "templateName",
        },
        {
          title: "Type",
          selected: false,
          isDisabled: false,
          filterKey: "templateType",
        },
        {
          title: "Smartlink ID",
          selected: false,
          isDisabled: false,
          filterKey: "smartlinkId",
        },
        {
          title: "Smartlink Splits",
          selected: false,
          isDisabled: false,
          filterKey: "smartlinkSplit",
        },
        {
          title: "Smartlink Author",
          selected: false,
          isDisabled: false,
          filterKey: "smartlinkAuthor",
        },
      ],
    },
  ],
  groupByList: [{
    value: "",
    title: "All",
  }, {
    value: "template",
    title: "Template",
  }, {
    value: "smartlink",
    title: "Smartlink",
  }],
};

const ConfigureColumnsComponent = ({
  open,
  onClose,
  onApply,
  initialGroupBy = defaultProps.initialGroupBy,
  initialFilterOrder = defaultProps.initialFilterOrder,
  initialGroups = defaultProps.initialGroups,
  groupByList = defaultProps.groupByList,
  getRequiredFilterKeyByGroupBy = defaultProps.getRequiredFilterKeyByGroupBy,
}: Props) => {
  const [groups, setGroups] = useState<Array<Group>>(initialGroups);
  const [filterOrder, setFilterOrder] = useState<Array<string>>([]);
  const [groupBy, setGroupBy] = useState<string>("");
  const [requireFiltersKey, setRequireFiltersKey] = useState<Array<string>>([]);
  const sortByOrder = (a: Filter, b: Filter) => filterOrder.indexOf(a.filterKey) - filterOrder.indexOf(b.filterKey);

  const setSelectedFilters = (filterKeys: Array<string>) => {
    setGroups((currentGroups) => currentGroups.map<Group>((group) => ({
      ...group,
      filters: group.filters.map<Filter>((filter) => ({
        ...filter,
        selected: filterKeys.includes(filter.filterKey),
      })),
    })));
    setFilterOrder(filterKeys);
  };

  useEffect(() => {
    const requireFilter = getRequiredFilterKeyByGroupBy(groupBy);
    setRequireFiltersKey(requireFilter);
    setGroups((currentGroups) => currentGroups.map<Group>((group) => ({
      ...group,
      filters: group.filters.map<Filter>((filter) => ({
        ...filter,
        selected: filter.selected || requireFilter.includes(filter.filterKey),
        isDisabled: requireFilter.includes(filter.filterKey),
      })),
    })));
    setFilterOrder((currentFilterOrder) => [...requireFilter, ...currentFilterOrder].filter(unique));
  }, [getRequiredFilterKeyByGroupBy, groupBy]);

  useEffect(() => {
    setSelectedFilters(initialFilterOrder);
  }, [initialFilterOrder]);

  useEffect(() => {
    setGroupBy(initialGroupBy);
  }, [initialGroupBy]);

  const handleFilterClick = ({ groupName, title, value }) => {
    const groupIndex = groups.findIndex((group) => group.title === groupName);
    const { filters } = groups[groupIndex];
    const filterIndex = filters.findIndex((item) => item.title === title);
    const { filterKey } = filters[filterIndex];
    const selectedFilterKeys = value ? [...filterOrder, filterKey] : filterOrder.filter((item) => item !== filterKey);
    setSelectedFilters(selectedFilterKeys);
  };

  const handleSelectAllFilters = (value: boolean) => {
    const selectedFilterKeys = [
      ...filterOrder,
      ...groups
        .map((group) => group.filters.map(({ filterKey }) => filterKey))
        .reduce((acc, arr) => acc.concat(arr), []),
    ]
      .filter((key) => value || requireFiltersKey.includes(key))
      .filter(unique);
    setSelectedFilters(selectedFilterKeys);
  };

  const filters: Filter[] = groups
    .map((group) => group.filters)
    .reduce<Array<Filter>>((acc, arr) => acc.concat(arr), []);

  const selectedFilters: FilterItem[] = filters
    .filter((filter) => !filter.isDisabled && filter.selected)
    .sort(sortByOrder)
    .map(mapFilter);

  const requiredFilters: FilterItem[] = filters
    .filter((filter: Filter) => filter.isDisabled && filter.selected)
    .map(mapFilter);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      data-testid="configureColumnModal"
    >
      <Header onClose={onClose} />
      <Body
        groups={groups}
        groupByList={groupByList}
        groupBy={groupBy}
        onChangeGroupBy={setGroupBy}
        onFilterDragEnd={dragEndWrapper(
          filterOrder.filter((k) => !requireFiltersKey.includes(k)), (newOrder) => setFilterOrder([
            ...requireFiltersKey,
            ...newOrder,
          ])
        )}
        selectedFilters={selectedFilters}
        requireFilters={requiredFilters}
        onFilterClick={handleFilterClick}
        onSelectAllFilters={handleSelectAllFilters}
      />
      <Divider />
      <Footer
        disabled={filterOrder.length === 0}
        onApply={() => onApply({
          groupBy,
          filterOrder,
        })}
      />
    </Dialog>
  );
};

export default ConfigureColumnsComponent;
