// @flow
import React, { useState } from "react";
import { SetupCard, Loader } from "@fas/ui-core";
import {
  Box, Chip, TextField, Button, Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getErrorMessageSelector, getFormField, setFormData } from "@fas/ui-framework";
import DeleteIcon from "@mui/icons-material/Delete";
import { removeError } from "@fas/ui-framework/lib/redux/actions/errors/actions";
import { toggleModal } from "@fas/ui-framework/lib/redux/actions/modals";
import {
  GENERAL_FILTER_CAMPAIGNS_OUT_MODAL,
  GENERAL_FILTER_FORM,
  GET_GENERAL_FILTER_SAGA,
  SAVE_GENERAL_FILTER_SAGA,
} from "../../helpers/constants";
import { useLoading } from "../../hooks";

type CampaignOut = {id: string, weight: number, isActive?: number };

function GeneralFilterCampaignOut() {
  const dispatch = useDispatch();
  const [enteredCampaigns, setEnteredCampaigns] = useState("");

  const campaignsOut: CampaignOut[] = useSelector((state) => getFormField(state, GENERAL_FILTER_FORM, "tdsCampaignsOut"));
  const isGetFormloading: boolean = useLoading(GET_GENERAL_FILTER_SAGA);
  const isSaveFormloading: boolean = useLoading(SAVE_GENERAL_FILTER_SAGA);
  const errorMessage: string = useSelector((state) => getErrorMessageSelector(state, "tdsCampaignsOut"));

  const handleRemoveCampaignOut = (idx: number) => {
    // $FlowFixMe
    dispatch(setFormData(GENERAL_FILTER_FORM, { tdsCampaignsOut: campaignsOut.toSpliced(idx, 1) }));
    dispatch(removeError(["tdsCampaignsOut"]));
  };

  const handleManualEnter = (e): void => {
    if (e.key === "Enter") {
      const { value } = e.target;
      const newValues: CampaignOut[] = [...campaignsOut];
      value.split(",").forEach((v) => {
        if (!newValues.find((i) => i.id === v)) {
          newValues.push({ id: v, weight: 0 });
        }
      });
      dispatch(setFormData(GENERAL_FILTER_FORM, { tdsCampaignsOut: newValues }));
      dispatch(removeError(["tdsCampaignsOut"]));
      setEnteredCampaigns("");
    }
  };

  const handleChangeWeight = (val: string, idx: number): void => {
    const newValues: CampaignOut[] = [...campaignsOut];
    newValues[idx].weight = Number(val);

    dispatch(setFormData(GENERAL_FILTER_FORM, { tdsCampaignsOut: newValues }));
    dispatch(removeError(["tdsCampaignsOut"]));
  };

  const handleEqualShares = (): void => {
    const totalLength: number = campaignsOut.length;
    const integerPart: number = Math.floor(100 / totalLength);
    const residualPart: number = 100 % totalLength;
    const newValues: CampaignOut[] = campaignsOut
      .map((item: CampaignOut, index: number): CampaignOut => ({
        ...item,
        weight: index < residualPart ? integerPart + 1 : integerPart,
      }));
    dispatch(setFormData(GENERAL_FILTER_FORM, { tdsCampaignsOut: newValues }));
    dispatch(removeError(["tdsCampaignsOut"]));
  };

  const handleChoiceCampaign = () => {
    dispatch(toggleModal(
      GENERAL_FILTER_CAMPAIGNS_OUT_MODAL,
      true
    ));
  };

  return (
    <Loader loading={isGetFormloading || isSaveFormloading}>
      <SetupCard title="TDS Campaign out">
        <>
          <Box mb={2} width={1} display="flex" alignItems="center" justifyContent="center">
            <TextField
              label="Add campaigns manualy here"
              size="small"
              fullWidth
              variant="outlined"
              type="text"
              margin="dense"
              value={enteredCampaigns}
              onChange={(e) => setEnteredCampaigns(e.target.value)}
              onKeyPress={handleManualEnter}
            />
            <Typography px={1}>
              or
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ whiteSpace: "nowrap" }}
              onClick={handleChoiceCampaign}
            >
              Choice campaign
            </Button>
          </Box>
          <Box display="flex" justifyContent="flex-end" width="100%" mb={1}>
            <Button
              variant="outlined"
              color="primary"
              sx={{ whiteSpace: "nowrap" }}
              onClick={handleEqualShares}
            >
              Equal shares
            </Button>
          </Box>
          <Box width={1} display="flex" justifyContent="space-between" mr={1} flexWrap="wrap" sx={{ borderBottom: (errorMessage ? "1px solid red" : "") }}>
            {!isGetFormloading && campaignsOut.map((c, idx) => (
              <Box key={c.id} display="flex" alignItems="center" justifyContent="space-between" width="49%" mb={1}>
                <Chip
                  size="small"
                  label={c.id}
                  sx={{ marginRight: 1 }}
                />
                <Box display="flex">
                  <TextField
                    id={idx.toString()}
                    sx={{ margin: 0, maxWidth: "150px" }}
                    type="number"
                    size="small"
                    margin="dense"
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                    value={c.weight}
                    onChange={(e) => handleChangeWeight(e.target.value, idx)}
                  />
                  <Button
                    color="primary"
                    onClick={() => handleRemoveCampaignOut(idx)}
                    data-testid="delete-button"
                  >
                    <DeleteIcon />
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
          {errorMessage && (<Box sx={{ color: "red" }}>{errorMessage}</Box>)}
        </>
      </SetupCard>
    </Loader>
  );
}

export default GeneralFilterCampaignOut;
