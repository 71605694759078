// @flow
import React from "react";
import DocumentTitle from "react-document-title";
import GeneralFilterFormV2 from "../../containers/GeneralFilterFormV2";

type Props = {
};

class GeneralFilterForm extends React.Component<Props> {
  campaign: Object;

  render() {
    return (
      <DocumentTitle title="General Filter">
        <GeneralFilterFormV2 />
      </DocumentTitle>
    );
  }
}

export default GeneralFilterForm;
