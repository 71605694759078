// @flow
import {
  all, call, put, takeEvery,
} from "redux-saga/effects";
import type { Effect, Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import { Map, fromJS } from "immutable";
import { ExternalRedirect } from "@fas/ui-core";
import { GENERAL_FILTER_FORM, GET_GENERAL_FILTER_SAGA } from "../../helpers/constants";
import { cloneGeneralFilter, getGeneralFilter, getValuesForTargeting } from "../../services/generalFilterApiV2";
import { type GetGeneralFilterSaga } from "../../actions/generalFilters";
import type { FormQbRule } from "../../selectors/generalFilterV2";
import { setTargetingValues } from "../../actions/dictionaries";

export function* makeFetch({ id, isClone }: GetGeneralFilterSaga): Saga<void> {
  try {
    yield put(setLoading(GET_GENERAL_FILTER_SAGA, true));

    let formData = {};

    if (!isClone) {
      const data = yield call(getGeneralFilter, id);
      formData = {
        ...data,
      };
    }

    if (isClone) {
      const data = yield call(cloneGeneralFilter, id);
      formData = {
        ...data,
      };
    }

    const targetings: {[string]: FormQbRule} = formData.conditions.byId;
    const listOfTargetings: string[] = [];
    // $FlowFixMe
    Object.values(targetings).forEach((rule: FormQbRule) => {
      if (rule.type === "rule") {
        listOfTargetings.push(rule.name);
      }
    });
    const valuesList: { [string]: Effect} = {};

    if (id) {
      for (let i: number = 0; i < listOfTargetings.length; i++) {
        valuesList[listOfTargetings[i]] = call(getValuesForTargeting, listOfTargetings[i]);
      }

      const apiValuesList: * = yield all(valuesList);

      const mappedApiValuesList: {
        [string]: Array<{ label: string, value: string, subValue?: Array<string> }>
      } = {};

      Object.keys(apiValuesList).forEach((key: string) => {
        mappedApiValuesList[key] = apiValuesList[key].data.data[0].values;
      });

      yield put(setTargetingValues({ data: mappedApiValuesList }));
    }

    yield put(setFormData(GENERAL_FILTER_FORM, {
      ...formData,
      conditions: Map({
        byId: fromJS(formData.conditions.byId),
        allIds: formData.conditions.allIds,
      }),
    }));
  }
  catch (error) {
    if (error?.response?.data?.status === 404) {
      ExternalRedirect({ to: "/404", target: "_self" });
      return;
    }
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(GET_GENERAL_FILTER_SAGA, false));
  }
}

export default function* watchGetFormDataSaga(): Saga<void> {
  yield takeEvery(GET_GENERAL_FILTER_SAGA, (makeFetch: Function));
}
