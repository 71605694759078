// @flow
import React from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  TextField,
} from "@mui/material";
import { ComboBox, SetupCard } from "@fas/ui-core";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";

const templateTypes = [
  "technical",
  "other",
];

type Props = $ReadOnly<{|
  name: string,
  disabledName?: boolean,
  type: string,
  errors: ErrorsMapper,
  onChangeName: (name: string) => void,
  onChangeType: (type: string) => void,
|}>;

const hasError = (key: string, errors: ErrorsMapper): boolean => Object.keys(errors).includes(key);

const TemplateGeneralInfo = ({
  name,
  disabledName = false,
  type,
  errors,
  onChangeName,
  onChangeType,
}: Props) => (
  <SetupCard title="General info">
    <>
      <Box width={1} mb={2}>
        <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
          <Grid item xs={3}>
            <Typography>Name</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                id="template-name"
                variant="outlined"
                size="small"
                value={name}
                disabled={disabledName}
                placeholder="Example: Template_test"
                onChange={(e) => onChangeName(e.target.value)}
                error={hasError("templateName", errors)}
                inputProps={{ maxLength: "60" }}
                data-testid="name-filter-text"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box width={1} mb={2}>
        <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
          <Grid item xs={3}>
            <Typography>Template type</Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControl variant="outlined" fullWidth>
              <ComboBox
                id="mui-autocomplete-domain"
                label="Domain"
                options={templateTypes}
                value={type}
                defaultValue={type}
                isOptionEqualToValue={(option, val) => option === val}
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    data-testid="mui-autocomplete-domain-text"
                    placeholder="Select template type"
                    variant="outlined"
                    error={hasError("templateType", errors)}
                    fullWidth
                  />
                )}
                onChange={(e, value) => onChangeType(value)}
                disableClearable
                data-testid="template-type-comboBox"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  </SetupCard>
);

export default TemplateGeneralInfo;
