// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { getTableData } from "@fas/ui-framework/lib/redux/selectors/table";
import type { StoreType } from "@fas/ui-framework/lib/services/table/types/table.types";
import { GENERAL_FILTER_TABLE } from "../../helpers/constants";
import type { GeneralFilterListItem } from "../GeneralFilterList/type";
import ExportCSVButton from "./ExportCSVButton";
import { useLoading } from "../../hooks";

const GeneralFilterActions: StatelessFunctionalComponent<{}> = () => {
  const isLoading: boolean = useLoading(GENERAL_FILTER_TABLE);

  const data: GeneralFilterListItem[] = useSelector((state: StoreType)
    : GeneralFilterListItem[] => getTableData(state, GENERAL_FILTER_TABLE));

  return (
    <Box display="flex" flexDirection="row" justifyContent="end" sx={{ position: "relative", zIndex: "10", marginBottom: "20px" }}>
      <ExportCSVButton data={data} disabled={(!data || !data.length || isLoading)} />
    </Box>
  );
};

export default GeneralFilterActions;
